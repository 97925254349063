import { createRoot } from 'react-dom/client';
import ReactGA from 'react-ga4';

import 'react-dates/initialize';

import Root from './Root';
import * as serviceWorker from './serviceWorker';

import '@fortawesome/fontawesome-free/css/all.min.css';
import 'rc-slider/assets/index.css';
import 'react-dates/lib/css/_datepicker.css';
import 'react-datetime/css/react-datetime.css';
import 'styles/bootstrap.scss';
import 'styles/css-variables.scss';
import 'styles/styles.scss';
const Sentry = import('@sentry/browser');

const googleAnalyticsId = process.env.NODE_ENV === 'production' ? 'G-JQY5CBWB5H' : 'G-QXK80Y1BF4';

// https://www.npmjs.com/package/react-ga4
ReactGA.initialize([
    {
        trackingId: googleAnalyticsId,
    },
]);

Sentry.then((s) => {
    s.init({
        dsn: 'https://648d332798a14fa6b753e3d86fb9c4aa@o4505509385928704.ingest.sentry.io/4505509388222465',
        integrations: [
            new s.BrowserTracing({
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: [
                    /^http:\/\/localhost/,
                    /^https:\/\/web.staging.optoscale.no/,
                    /^https:\/\/web.optoscale.no/,
                ],
            }),
            new s.Replay({
                maskAllText: false,
                maskAllInputs: false,
                blockAllMedia: false,
            }),
        ],
        environment: process.env.NODE_ENV,
        // Performance Monitoring
        tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.1 : 1.0, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: process.env.NODE_ENV === 'production' ? 0.01 : 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
});

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<Root />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
