import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/atoms/Buttons';
import NoData from 'components/NoData';

import styles from './BioscopeConfiguration.module.scss';

export interface BioscopeConfigurationProps {
    children?: React.ReactNode;
    bioscope?: { id: number }; // Assuming bioscope prop contains an id
    configData: any;
    handlePublish: (topic: string, message: string) => void;
}

//   const BioscopeConfiguration = ({ children, bioscope }: BioscopeConfigurationProps) => {
//     const { client, isConnected, subscribe } = useMqtt();
//     const { t } = useTranslation();
//     const title = 'Bioscope Configuration';
//     const [configData, setConfigData] = useState<any>(null);

//     const messageHandler = useCallback((topic: string, message: Buffer) => {
//       const parsedMessage = JSON.parse(message.toString());
//     //   console.log(`Received message from ${topic}:`, parsedMessage);
//       setConfigData(parsedMessage);
//     }, []);

//     useEffect(() => {
//       if (isConnected && bioscope?.id) {
//         const topic = `bioscope/config/response/${bioscope.id}`;
//         subscribe(topic, { qos: 1 });

//         client?.on('message', messageHandler);

//         return () => {
//           client?.off('message', messageHandler);
//         };
//       }
//     }, [isConnected, bioscope?.id, subscribe, client, messageHandler]);

//     const handlePublish = useCallback(() => {
//       if (client && isConnected) {
//         const topic = `bioscope/config/${bioscope?.id}`;
//         const message = JSON.stringify({ action: 'fetch_config' });
//         client.publish(topic, message, { qos: 1 }, (error) => {
//           if (error) {
//             console.error('Publish error:', error);
//           } else {
//             console.log(`Message published to ${topic}`);
//           }
//         });
//       }
//     }, [client, isConnected, bioscope?.id]);

//     return (
//       <div className={styles.bioscopeconfiguration}>
//         <h2>{title}</h2>
//         {children}
//         <div className="mt-3">
//           <Button type="button" onClick={handlePublish}>Refresh Config</Button>
//         </div>
//         <div className="mt-3">
//           {configData ? (
//             <table className="text-nowrap">
//               <tbody>
//                 {Object.keys(configData).map(key => (
//                   <tr key={key}>
//                     <td><strong>{key}</strong></td>
//                     <td>{configData[key]}</td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           ) : (
//             <NoData color={'warning'} />
//           )}
//         </div>
//       </div>
//     );
//   };

const BioscopeConfiguration = ({
    children,
    bioscope,
    configData,
    handlePublish,
}: BioscopeConfigurationProps) => {
    const { t } = useTranslation();
    const title = 'Bioscope Configuration';

    const handleRefresh = () => {
        const topic = `bioscope/config/${bioscope?.id}`;
        const message = 'fetch_bioscope_config';
        handlePublish(topic, message);
    };
    return (
        <div className={styles.BioscopeConfiguration}>
            <h2>{title}</h2>
            {children}
            <div className="mt-3">
                <Button type="button" onClick={handleRefresh}>
                    Refresh
                </Button>
            </div>
            <div className="mt-3">
                {configData ? (
                    <table className="text-nowrap">
                        <tbody>
                            {Object.keys(configData).map((key) => (
                                <tr key={key}>
                                    <td>
                                        <strong>{key}</strong>
                                    </td>
                                    <td>
                                        {key === 'timestamp'
                                            ? new Date(configData[key]).toLocaleString()
                                            : typeof configData[key] === 'boolean'
                                              ? configData[key].toString()
                                              : typeof configData[key] === 'object'
                                                  ? JSON.stringify(configData[key])
                                                  : configData[key]}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <NoData color={'warning'} />
                )}
            </div>
        </div>
    );
};

export default BioscopeConfiguration;
export { BioscopeConfiguration };
