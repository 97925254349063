import React, { useEffect, useRef, useState } from 'react';
import { Buffer } from 'buffer';
import { t } from 'i18next';
import { MqttClient } from 'mqtt/*';
import { match } from 'ts-pattern';

import { IconButton } from '@/components/atoms/Buttons';
import { GenericIcon } from '@/components/atoms/icons/GenericIcon';
import * as iconUrls from '@/components/atoms/icons/GenericIcon/GenericIcons';
import { OptoTooltip, TimeStampAsToolTip } from '@/components/atoms/OptoTooltip';
import { ToastInfo } from '@/components/molecules/ToastTemplate/ToastTemplate';
import type {
    WinchFrontendData,
    WinchPosition,
} from '@/routes/Client/Location/pages/LocationWinch/MqttTopics';
import {
    type MqttTopics,
    transformToGUIWinchData,
} from '@/routes/Client/Location/pages/LocationWinch/MqttTopics';
import { CagesWithActiveBioscopesByLocationDto } from '@/services/types';

import { OptoDropDown } from '../OptoDropDown';
import { OptoDropDownOption } from '../OptoDropDown/DropDown';

import { getStatusColor, getStatusIcon, prepareCoordinatesText } from './helperFunctionsWinch';

import styles from './LocationWinchCard.module.scss';

export interface LocationWinchCardProps {
    cage: CagesWithActiveBioscopesByLocationDto;
    client?: MqttClient | null;
    bioscopeId: number;
    currentTopic?: MqttTopics;
    resetAction?: boolean;
    onrResetChange?: (reset: boolean) => void;
    openSettingsModal: ({
        cageId,
        cageName,
        winchId,
        settings,
        bioscopeId,
    }: {
        cageId: string;
        cageName: string;
        winchId: string;
        settings: WinchFrontendData['settings'];
        bioscopeId: number;
    }) => void;
    openMoveModal: ({
        cageId,
        cageName,
        winchId,
        settings,
        bioscopeId,
        position,
        timestamp,
    }: {
        cageId: string;
        cageName: string;
        winchId: string;
        settings: WinchFrontendData['settings'];
        position: WinchFrontendData['currentWinchPosition']['position'];
        bioscopeId: number;
        timestamp: WinchFrontendData['timestamp'];
    }) => void;
}

const LocationWinchCard = ({
    currentTopic,
    client,
    cage,
    bioscopeId,
    openSettingsModal,
    openMoveModal,
    resetAction,
}: LocationWinchCardProps) => {
    const [bioscopeStatus, setBioscopeStatus] = useState<{ status: string; timestamp: string }>({
        status: '',
        timestamp: '',
    });
    const [winchStatus, setWinchStatus] = useState<{
        winchStatus?: string;
        status: string;
        timestamp: string;
    }>({
        winchStatus: '',
        status: '',
        timestamp: '',
    });

    const [selectedAction, setSelectedAction] = useState<{
        value: string;
        text: string;
    } | null>(null);

    const [lastToastMessage, setLastToastMessage] = useState<string | null>(null);

    const [winchData, setWinchData] = useState<WinchFrontendData | null>(null);
    const [selectedPosition, setSelectedPosition] = useState<WinchPosition | null>(null);
    const ref = useRef<HTMLDivElement>(null);

    const showToast = (message: string, type: 'info' | 'success' | 'error', icon: string) => {
        ToastInfo({
            content: message,
            type,
            icon,
        });
    };

    const handleMessage = (topic: string, message: Buffer) => {
        if (!currentTopic) {
            console.error('No current topic');
            return;
        }

        match(topic)
            .with(currentTopic?.subscribe_topics?.bioscope_status, () => {
                const { status, timestamp } = JSON.parse(message.toString()) as {
                    status: string;
                    timestamp: string;
                };
                setBioscopeStatus({ status, timestamp });
            })
            .with(currentTopic?.subscribe_topics.winch_status_response, () => {
                const winchStatusData = JSON.parse(message.toString());
                const transformed = transformToGUIWinchData(winchStatusData);

                setWinchData((prevState) => ({
                    ...prevState,
                    ...transformed,
                }));

                setWinchStatus((prevState) => {
                    if (prevState.winchStatus === 'moving' && transformed.winchStatus === 'idle') {
                        setSelectedAction({ value: 'default', text: '' });
                        setSelectedPosition(null);
                    }

                    return {
                        ...prevState,
                        winchStatus: transformed.winchStatus,
                        timestamp: transformed.timestamp,
                    };
                });

                const toastMessage = JSON.stringify({
                    winchId: transformed.winchId,
                    winchStatus: transformed.winchStatus,
                    bioscopeStatus: bioscopeStatus.status,
                });

                if (lastToastMessage !== toastMessage) {
                    if (transformed.winchStatus === 'idle') {
                        showToast(
                            `${t('winch')} ID: ${transformed.winchId}, ${t('Status')}: ${
                                transformed.winchStatus
                            }`,
                            'info',
                            'winch'
                        );
                    } else if (transformed.winchStatus === 'moving') {
                        showToast(
                            `${t('winch')} ID: ${transformed.winchId}, ${t('Status')}: ${
                                transformed.winchStatus
                            }`,
                            'success',
                            'winch'
                        );
                    }
                    setLastToastMessage(toastMessage);
                }
            })
            .with(currentTopic?.subscribe_topics.winch_stop_response, () => {
                const response = JSON.parse(message.toString());
                setSelectedAction({ value: 'default', text: '' });
                showToast(
                    response.message,
                    response.status === 'success' ? 'success' : 'error',
                    'winch'
                );
            })
            .with(currentTopic?.subscribe_topics.winch_move_response, () => {
                const response = JSON.parse(message.toString());
                showToast(
                    response.message,
                    response.status === 'success' ? 'success' : 'error',
                    'winch'
                );
            })
            .with(currentTopic?.subscribe_topics.winch_settings_set_response, () => {
                const response = JSON.parse(message.toString());
                showToast(
                    response.message,
                    response.status === 'success' ? 'success' : 'error',
                    'winch'
                );
            })
            .otherwise(() => {
                // console.log('No matching incoming handler on topic:', topic);
            });
    };

    const handleSelectOption = (event: Event) => {
        const customEvent = event as CustomEvent;
        const { name, buttonText } = customEvent.detail;
        setSelectedAction({ value: name, text: buttonText });
    };

    useEffect(() => {
        if (resetAction) {
            setSelectedAction({ value: 'default', text: '' });
        }
    }, [resetAction]);

    useEffect(() => {
        if (client?.connected && currentTopic) {
            client.on('message', handleMessage);
        } else {
            console.log('No client or not connected');
        }

        return () => {
            if (client?.connected) {
                client.removeListener('message', handleMessage);
            }
        };
    }, [client, currentTopic]);

    useEffect(() => {
        const currentRef = ref.current;
        if (currentRef) {
            currentRef.addEventListener('selected', handleSelectOption);
        }

        return () => {
            if (currentRef) {
                currentRef.removeEventListener('selected', handleSelectOption);
            }
        };
    }, []);

    const handleManualMoveClick = () => {
        if (winchData) {
            setSelectedPosition({
                id: 'manual',
                name: 'manual',
                position: winchData.currentWinchPosition.position,
            });
        } else {
            console.error('No winchData available');
        }
    };

    const handleMoveClick = () => {
        if (client && currentTopic && selectedPosition) {
            const moveTopic = currentTopic.publish_topics.winch_move;
            const positionData = {
                x: selectedPosition.position.x,
                y: selectedPosition.position.y,
            };
            const message = JSON.stringify(positionData);
            client.publish(moveTopic, message);
        } else {
            console.error(
                'Client not connected, current topic not set, or selected position is null'
            );
        }
    };

    const handleStopClick = () => {
        if (client && currentTopic) {
            const stopTopic = currentTopic.publish_topics.winch_stop;
            const message = currentTopic.messages.winch_stop_message;
            client.publish(stopTopic, message);
        } else {
            console.error('Client not connected or current topic not set');
        }
    };

    return (
        <div
            className={styles.locationwinchcard}
            data-bioscopeid={cage?.bioscopeId}
            data-view="card"
            ref={ref}>
            <div>
                <span
                    data-type="status"
                    data-state={winchStatus.winchStatus}
                    data-updated={winchStatus.timestamp}>
                    <OptoTooltip
                        nub="left-center"
                        content={TimeStampAsToolTip({
                            timeStamp: winchStatus.timestamp,
                            timeFormat: 'dateTime',
                            children: (
                                <>
                                    {`${t('Bioscope status')}: `}
                                    <span data-status={bioscopeStatus.status}>
                                        {bioscopeStatus.status || 'unknown'}
                                    </span>
                                </>
                            ),
                        })}>
                        <div
                            className={styles.animatedStatus}
                            data-animationtype={winchStatus.winchStatus}>
                            <div data-animation>
                                <GenericIcon
                                    icon={getStatusIcon(
                                        winchStatus.winchStatus,
                                        bioscopeStatus.status
                                    )}
                                    color={getStatusColor(
                                        winchStatus.winchStatus,
                                        bioscopeStatus.status
                                    )}
                                />
                                <div>{winchStatus.winchStatus || '----'}</div>
                            </div>
                        </div>
                    </OptoTooltip>
                </span>

                <span data-cagename="cagename">{cage?.cageName}</span>
                <span data-type="cageId">{cage?.cageId}</span>
                <span data-type="winchId">{winchData?.winchId || 'no winch detected'}</span>
                <span data-type="coordinates">
                    {prepareCoordinatesText(winchData) || 'No winch, no position.'}
                </span>
                <div style={{ width: '200px' }}>
                    <OptoDropDown
                        name="select action"
                        id={`${winchData?.winchId}select`}
                        disabled={winchData?.winchStatus === 'moving'}
                        selected={selectedAction}>
                        {winchData?.positions.map((position) => (
                            <OptoDropDownOption
                                selected={selectedAction?.value === position.name}
                                name={position.name}
                                key={position.id}
                                disabled={position.position.x === 0 && position.position.y === 0}
                                variant="secondary"
                                buttonText={t(position.name) as string}
                                onClick={() => setSelectedPosition(position)}
                            />
                        ))}
                        <OptoDropDownOption
                            selected={selectedAction?.value === 'manual'}
                            buttonText={t('move manually') as string}
                            name="manual"
                            disabled={bioscopeStatus.status !== 'online'}
                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                if (winchData) {
                                    handleManualMoveClick();
                                    openMoveModal({
                                        cageId: cage?.cageId?.toString() || '0',
                                        cageName: cage?.cageName,
                                        winchId: winchData.winchId,
                                        settings: winchData.settings,
                                        bioscopeId,
                                        position: winchData.currentWinchPosition.position,
                                        timestamp: winchData.timestamp,
                                    });
                                } else {
                                    console.error('No winchData, No modal');
                                }
                            }}
                        />
                    </OptoDropDown>
                </div>
                {winchData?.winchStatus === 'idle' ? (
                    <IconButton
                        disabled={bioscopeStatus.status !== 'online' || !selectedPosition}
                        variant="secondary"
                        buttonText={t('Move') as string}
                        onClick={handleMoveClick}
                        tooltip={
                            !winchData?.calibrationStatus
                                ? (t('Winch not calibrated, calibrate it manually') as string)
                                : ''
                        }
                    />
                ) : winchData?.winchStatus === 'moving' ? (
                    <IconButton
                        disabled={bioscopeStatus.status !== 'online'}
                        variant="danger"
                        buttonText={t('Stop') as string}
                        onClick={handleStopClick}
                        tooltip={
                            !winchData?.calibrationStatus
                                ? (t('Winch not calibrated, calibrate it manually') as string)
                                : ''
                        }
                    />
                ) : (
                    <IconButton
                        disabled
                        variant="secondary"
                        buttonText={t('Move') as string}
                        tooltip="Non existing winch cannot be moved."
                    />
                )}
                <IconButton
                    variant="secondary"
                    disabled={bioscopeStatus.status !== 'online'}
                    tooltip={
                        !winchData?.calibrationStatus
                            ? (t('Winch not calibrated, calibrate it manually') as string)
                            : (t('Settings') as string)
                    }
                    onlyIcon
                    onClick={() => {
                        if (winchData?.settings) {
                            openSettingsModal({
                                cageId: cage?.cageId?.toString() || '0',
                                cageName: cage?.cageName,
                                winchId: winchData.winchId,
                                settings: winchData.settings,
                                bioscopeId,
                            });
                        } else {
                            console.error('No winchData, No modal');
                        }
                    }}>
                    <GenericIcon icon={iconUrls.SettingsIconSVG} />
                </IconButton>
            </div>
        </div>
    );
};

const LocationWinchCardHeader = () => (
    <div className={styles.locationwinchcard} data-type="header">
        <div>
            <span data-type="status">{t('status')}</span>
            <span data-cagename="cagename">{t('Cage name')}</span>
            <span data-type="cageId">{t('Cage')} ID</span>
            <span data-type="winchId">{t('winch')} ID</span>
            <span data-type="coordinates">{t('coordinates')}</span>
            <span>{t('Actions')}</span>
            <span>{t('CTRL')}</span>
            <span>{t('Settings')}</span>
        </div>
    </div>
);

export default LocationWinchCard;
export { LocationWinchCard, LocationWinchCardHeader };
