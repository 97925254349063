import React from 'react';

import styles from './ChevronDirectionDownIcon.module.scss';

export interface ChevronDirectionDownIconProps {
    children?: React.ReactNode;
    color?: string;
}

const ChevronDirectionDownIcon = ({ color = 'currentColor' }) => {
    return (
        <div className={styles.chevrondirectiondownicon}>
            <svg id="glyphicons-arrows" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                <path
                    fill={color}
                    id="downwards-chevron"
                    d="M27.121,12.70709,16.70709,23.121a1,1,0,0,1-1.41418,0L4.879,12.70709a1,1,0,0,1,0-1.41418L6.29291,9.879a1,1,0,0,1,1.41418,0L16,18.17188,24.29291,9.879a1,1,0,0,1,1.41418,0L27.121,11.29291A1,1,0,0,1,27.121,12.70709Z"
                />
            </svg>
        </div>
    );
};

export default ChevronDirectionDownIcon;
export { ChevronDirectionDownIcon };
