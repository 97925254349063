export interface WinchSettings {
    max: Position;
    min: Position;
    distanceToPulley: number;
}

type Position = {
    x: number;
    y: number;
    unit?: string;
};

export type WinchPosition = {
    name: string;
    id: string;
    position: Position;
};

export interface WinchFrontendData {
    settings: WinchSettings;
    positions: WinchPosition[];
    currentWinchPosition: WinchPosition;
    timestamp: string;
    winchId: string;
    winchStatus: string;
    calibrationStatus: boolean;
}

export interface WinchData {
    winchStatus: string; //  "idle" | "moving"
    calibrated: boolean;
    upperState: number;
    lowerState: number;
    error: number | string;
    manualSpeed: number;
    automationSpeed: number;
    upperEncoder: number;
    lowerEncoder: number;
    totalUpperRopeM: number;
    totalLowerRopeM: number;
    spooledOutUpperRopeM: number;
    spooledOutLowerRopeM: number;
    distanceToPulley: number;
    version: string;
    xMax: number;
    xMin: number;
    yMax: number;
    yMin: number;
    a: number;
    b: number;
    c: number;
    position: Position;
    calibrationStatus: boolean; //use this to check the calibration
    depth: {
        value: number;
        unit: string;
    };
    winch_settings: {
        distanceToPulley: number;
        max: Position;
        min: Position;
    };
    hostName: string;
    winchId: string;
    timestamp: string;
    saved_positions: WinchPosition[];
}

export type MqttTopics = {
    publish_topics: {
        winch_status: string;
        winch_settings_set: string;
        winch_settings_get: string;
        winch_move: string;
        winch_stop: string;
    };
    subscribe_topics: {
        bioscope_status: string;
        winch_status_response: string;
        winch_settings_set_response: string;
        winch_settings_get_response: string;
        winch_positions_get_response: string;
        winch_stop_response: string;
        winch_move_response: string;
    };
    messages: {
        winch_status_message: string;
        winch_settings_get_message: string;
        winch_stop_message: string;
    };
};

const template = (bioscopeId: number | string): MqttTopics => {
    return {
        publish_topics: {
            winch_status: `winch/status/${bioscopeId}`,
            winch_settings_set: `winch/settings/set/${bioscopeId}`,
            winch_settings_get: `winch/settings/get/${bioscopeId}`,
            winch_move: `winch/move/${bioscopeId}`,
            winch_stop: `winch/stop/${bioscopeId}`,
        },

        subscribe_topics: {
            bioscope_status: `bioscope/status/${bioscopeId}`,
            winch_status_response: `winch/status/response/${bioscopeId}`,
            winch_settings_set_response: `winch/settings/set/response/${bioscopeId}`,
            winch_settings_get_response: `winch/settings/get/response/${bioscopeId}`,
            winch_positions_get_response: `winch/positions/get/response/${bioscopeId}`,
            winch_stop_response: `winch/stop/response/${bioscopeId}`,
            winch_move_response: `winch/move/response/${bioscopeId}`,
        },

        messages: {
            winch_status_message: 'fetch_winch_status',
            winch_settings_get_message: 'fetch_winch_settings',
            winch_stop_message: 'stop_winch',
        },
    };
};

const transformToGUIWinchData = (data: WinchData): WinchFrontendData => {
    return {
        settings: {
            max: data.winch_settings?.max || { x: 0, y: 0 },
            min: data.winch_settings?.min || { x: 0, y: 0 },
            distanceToPulley: data.winch_settings?.distanceToPulley || 0,
        },
        positions: data.saved_positions || [],
        currentWinchPosition: {
            name: data.hostName,
            id: data.winchId,
            position: {
                x: data.position.x, // diagonal position in pen
                y: data.position.y, // depth in pen for testing
                unit: data.depth.unit,
            },
        },
        timestamp: data.timestamp,
        winchId: data.winchId,
        winchStatus: data.winchStatus,
        calibrationStatus: data.calibrationStatus,
    };
};

const getBioscopeIdFromMgttTopic = (topic: string): string => {
    const partsArr = topic.split('/');
    return partsArr[partsArr.length - 1];
};

export default template;
export { template as mqttTopics, getBioscopeIdFromMgttTopic, transformToGUIWinchData };
