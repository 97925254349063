import { t } from 'i18next';
import { useClientController_AllLiceWeeklyReport } from 'services/hooks';

import { Page, PageContent } from '@/components/Layout';
import { GenericSidebar, SidebarType } from '@/components/molecules/GenericSidebar';
import ToastInfoTemplate from '@/components/molecules/ToastTemplate';
import { useMe } from '@/contexts/meContext';
import regionSidebarOptions from '@/routes/Client/Overview';
import RegionLiceReportPageContent from '@/routes/Client/Overview/RegionLiceReport/RegionLiceReportPageContent';
import { Loader } from 'components/atoms/Loader';
import SomethingHappened from 'components/SomethingHappened';

interface RegionLiceReportProps {
    clientId: number;
}

const RegionLiceReportContainer = ({ clientId }: RegionLiceReportProps) => {
    const { state } = useMe();
    const layerUrlPrefix = `/c/${clientId}`;

    const client = state.clients.find((client) => client.id === clientId);

    const { isLoading, data, error } = useClientController_AllLiceWeeklyReport(clientId);

    ToastInfoTemplate({
        content: t('Lice report missing pen explain'),
        page: 'lice-report',
        icon: 'lice',
        type: 'info',
    });

    return (
        <Page title={t('Lice report')}>
            <GenericSidebar
                sideBarOptions={regionSidebarOptions}
                sidebarType={SidebarType.regionLevel}
                layerUrlPrefix={layerUrlPrefix}
            />
            <PageContent>
                {(isLoading || !data?.data) && <Loader />}
                {error && <SomethingHappened />}

                {data?.data && (
                    <RegionLiceReportPageContent
                        clientId={clientId}
                        clientName={client?.name}
                        reports={data?.data}
                    />
                )}
            </PageContent>
        </Page>
    );
};

export default RegionLiceReportContainer;
export type { RegionLiceReportProps };
export { RegionLiceReportContainer };
