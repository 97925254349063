import React from 'react';

import {
    UmerBoxElementLeftRightInfoSimple,
    UmerBoxElementLeftRightInfoSimpleProps,
} from '@/components/atoms/UmerBoxElements/UmerBoxElementLeftRightInfoSimple/UmerBoxElementLeftRightInfoSimple';

import styles from './UmerBoxElementLeftRightInfo.module.scss';

export interface UmerBoxElementLeftRightInfoProps {
    title?: string;
    info?: UmerBoxElementLeftRightInfoSimpleProps[];
}

const UmerBoxElementLeftRightInfo = ({ title = '', info }: UmerBoxElementLeftRightInfoProps) => {
    return (
        <div className={styles.umerBoxElementLeftRightInfo}>
            <div className={styles.inactiveBioscopsListContainer}>
                <div className={styles.inactiveBioscopeCageName}>{title}</div>
                {info?.map((info: UmerBoxElementLeftRightInfoSimpleProps, index: number) => {
                    return (
                        <UmerBoxElementLeftRightInfoSimple
                            key={`${info?.rightSide} ${index}`}
                            leftSide={info?.leftSide}
                            rightSide={info?.rightSide}
                            icon={info?.icon}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default UmerBoxElementLeftRightInfo;
export { UmerBoxElementLeftRightInfo };
