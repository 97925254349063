interface EnsureUpdatedValueFunction {
    (newValue: string | undefined, oldValue: number): number;
}

export const ensureUpdatedValue: EnsureUpdatedValueFunction = (
    newValue: string | undefined,
    oldValue: number
): number => {
    const parsedValue = newValue ? parseInt(newValue) : NaN;

    if (!Number.isNaN(parsedValue) && parsedValue !== oldValue) {
        return parsedValue;
    }

    return oldValue;
};
