import React from 'react';

import styles from './UmerBox.module.scss';

export interface UmerBoxProps extends React.HTMLAttributes<HTMLDivElement> {
    children?: React.ReactNode;
    supportedRoles?: string[];
    role?: string;
    id?: string;
    doublePadding?: boolean;
}

const UmerBox = ({
    children,
    role = 'default',
    supportedRoles = [],
    id,
    doublePadding = false,
}: UmerBoxProps) => {
    const elementId = id
        ? {
              id,
          }
        : {};

    const userHasRole = supportedRoles.includes(role) || supportedRoles.length === 0;
    return (
        <>
            {userHasRole ? (
                <div
                    {...elementId}
                    className={`${styles.umerbox} ${
                        doublePadding ? styles.umerboxDoublePadding : ''
                    }`}>
                    {children}
                </div>
            ) : null}
        </>
    );
};

export default UmerBox;
export { UmerBox };
