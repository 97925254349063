import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import mqtt, { IClientPublishOptions, IClientSubscribeOptions, MqttClient } from 'mqtt';
import { useMqttJwtController_generateToken } from 'services/hooks';

interface MqttContextType {
    client: MqttClient | null;
    isConnected: boolean;
    publish: (topic: string, message: string, options?: IClientPublishOptions) => void;
    subscribe: (topic: string, options?: IClientSubscribeOptions) => void;
    unsubscribe: (topic: string, options?: IClientSubscribeOptions) => void;
}

const MqttContext = createContext<MqttContextType | undefined>(undefined);

export const useMqtt = (): MqttContextType => {
    const context = useContext(MqttContext);
    if (!context) {
        throw new Error('useMqtt must be used within an MqttProvider');
    }
    return context;
};

const MqttProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const DEBUG = false;

    const [client, setClient] = useState<MqttClient | null>(null);
    const [isConnected, setIsConnected] = useState(false);
    const { mutateAsync: jwtResponse } = useMqttJwtController_generateToken();
    const [tokenExpiryTime, setTokenExpiryTime] = useState<number | null>(null);
    const [token, setToken] = useState<string | null>(null);

    // Function to fetch JWT token
    const fetchJwtToken = async () => {
        try {
            const jwtPayload = {
                iss: 'service', // Issuer
                sub: 'client', // Subject (user identifier)
                aud: 'hivemq', // Audience (your MQTT broker or application)
                scope: ['publish', 'subscribe'], // Scope
                expiresIn: {},
            };

            const jwtToken = await jwtResponse({ requestBody: jwtPayload });
            const token = jwtToken?.data.token; // Retrieve the token directly
            setToken(token); // Set token after fetching
            const tokenExpiryTime = jwtToken?.data.exp; // Extract expiry time from the token
            setTokenExpiryTime(tokenExpiryTime);
        } catch (error) {
            console.error('Failed to generate JWT token:', error);
        }
    };

    const connectToMqtt = async () => {
        try {
            const url = 'wss://optoscale-vbie1j.a03.euc1.aws.hivemq.cloud:8884/mqtt';
            const options = {
                username: 'optoscale_frontend', // JWT token is used as a password; username can be left unused
                password: token, // JWT token as password
                clientId: `mqttjs_${Math.random().toString(16).substr(2, 8)}`,
                reconnectPeriod: 0, // Reconnect every 10 seconds if disconnected
                connectTimeout: 30 * 1000, // Timeout after 30 seconds
            };

            // Connect to MQTT broker
            const mqttClient = mqtt.connect(url, options);
            setClient(mqttClient); // Save the client instance to state

            mqttClient.on('connect', () => {
                setIsConnected(true);
                console.log('Connected to MQTT broker');
            });

            mqttClient.on('disconnect', () => {
                setIsConnected(false);
                console.log('Disconnected from MQTT broker');
            });

            mqttClient.on('error', (err) => {
                console.error('MQTT connection error:', err);
            });

            // Cleanup on component unmount
            return () => {
                if (mqttClient) {
                    mqttClient.end(); // Disconnect the client if the component is unmounted
                }
            };
        } catch (error) {
            console.error('Failed to generate JWT or connect to MQTT:', error);
        }
    };

    useEffect(() => {
        fetchJwtToken();

        // Token refresh before token expiry
        const tokenInterval = setInterval(
            async () => {
                await fetchJwtToken(); // Fetch new token periodically
                console.log('Token refreshed');
            },
            59.5 * 60 * 1000
        ); // 59.5 minutes

        return () => clearInterval(tokenInterval); // Cleanup the interval on component unmount
    }, []);

    useEffect(() => {
        if (token && !isConnected) {
            connectToMqtt();
        }
    }, [token]);

    const publish = (
        topic: string,
        message: string,
        options: IClientPublishOptions = { qos: 1 }
    ) => {
        if (client && isConnected) {
            client.publish(topic, message, options, (err) => {
                if (err) {
                    console.error(`Failed to publish to ${topic}: `, err);
                } else {
                    DEBUG && console.log(`Published to ${topic}: `, message);
                }
            });
        }
    };

    const subscribe = (topic: string, options: IClientSubscribeOptions = { qos: 1 }) => {
        if (client && isConnected) {
            client.subscribe(topic, options, (err) => {
                if (err) {
                    console.error(`Failed to subscribe to ${topic}: `, err);
                } else {
                    DEBUG && console.log(`Subscribed to ${topic}`);
                }
            });
        }
    };

    const unsubscribe = (topic: string, options: IClientSubscribeOptions = { qos: 1 }) => {
        if (client && isConnected) {
            client.unsubscribe(topic, options, (err) => {
                if (err) {
                    console.error(`Failed to unsubscribe from ${topic}: `, err);
                } else {
                    DEBUG && console.log(`Unsubscribed from ${topic}`);
                }
            });
        }
    };

    return (
        <MqttContext.Provider value={{ client, isConnected, publish, subscribe, unsubscribe }}>
            {children}
        </MqttContext.Provider>
    );
};

export default MqttProvider;
