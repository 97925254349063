import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { GenericIcon } from '@/components/atoms/icons/GenericIcon';
import * as iconUrls from '@/components/atoms/icons/GenericIcon/GenericIcons';
import DropDownPanel from '@/components/molecules/DropDownPanel';

import { IconButton } from '../Buttons';

import styles from './DownloadImages.module.scss';

export interface DownloadImagesProps {
    imageList: any[];
    imageIndex?: number;
}

const DownloadImages = ({ imageList, imageIndex = 0 }: DownloadImagesProps) => {
    const { t } = useTranslation();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const options = ['Single', 'All'];
    const Download = (option) => {
        if (option === 'Single') handleSingleDownloadImage();
        else handleAllDownloadImages();
    };

    const handleSingleDownloadImage = async () => {
        const originalImage = imageList[imageIndex].path;
        const fileName = `${originalImage.substring(originalImage.lastIndexOf('/') + 1)}.webp`;
        const image = await fetch(originalImage, {
            credentials: 'include',
        });
        const imageBlog = await image.blob();
        const imageURL = URL.createObjectURL(imageBlog);
        const link = document.createElement('a');
        link.href = imageURL;
        link.download = fileName;
        link.click();
    };

    const handleAllDownloadImages = async () => {
        const JSZip = import('jszip');

        const zip = (await JSZip).default();

        const imageDownloads = imageList.map(async (image) => {
            const result = await fetch(image.path, {
                credentials: 'include',
            });
            const fileName = `${image.path.substring(image.path.lastIndexOf('/') + 1)}.webp`;

            const buffer = await result.arrayBuffer();
            zip.file(fileName, buffer, { binary: true });
        });

        await Promise.all(imageDownloads);
        zip.generateAsync({ type: 'blob' }).then((content) => {
            const objectUrl = URL.createObjectURL(content);
            const link = document.createElement('a');

            link.download = 'sample.zip';
            link.href = objectUrl;
            link.click();
        });
    };
    return (
        <>
            <div className={styles.downloadImages}>
                <IconButton onlyIcon={true} onClick={toggle} name="download" size="small">
                    <GenericIcon icon={iconUrls.DownloadFileIconSVG} />
                    <GenericIcon icon={iconUrls.ChecvronLineIconSVG} />
                </IconButton>
                <DropDownPanel open={dropdownOpen}>
                    {options.map((option, index) => {
                        const id = uuidv4();
                        return (
                            <IconButton
                                variant="tertiary"
                                key={id}
                                size="small"
                                buttonText={t(options[index])}
                                onClick={() => Download(option)}
                            />
                        );
                    })}
                </DropDownPanel>
            </div>
        </>
    );
}; //

export default DownloadImages;
export { DownloadImages };
