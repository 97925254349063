import { useEffect } from 'react';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from 'reactstrap';
import { Buffer } from 'buffer';
import { BioscopeImageCardContainer } from 'routes/Client/BioscopeDetail/BioscopeImageCardContainer';
import { BioscopeDetail as BioscopeDetailTypes } from 'services/types';

import { Box } from '@/components/Layout';
import { BioscopeSystemReadings } from '@/components/molecules/BioscopeSystemReadings/BioscopeSystemReadings';
import { WinchControl } from '@/components/molecules/WinchControl/WinchControl';
import { useMqtt } from '@/contexts/mqtt-provider-context';
import { LastMeasurement } from 'components/atoms/LastMeasurement/LastMeasurement';
import { LastPlacement } from 'components/atoms/LastPlacement/LastPlacement';
import Loader from 'components/atoms/Loader';
import { BioscopeConfiguration } from 'components/molecules/BioscopeConfiguration/BioscopeConfiguration';
import { BioscopeConnection } from 'components/molecules/BioscopeConnection/BioscopeConnection';
import { BioscopeMeta } from 'components/molecules/BioscopeMeta/BioscopeMeta';
import { BioscopePosition } from 'components/molecules/BioscopePosition/BioscopePosition';
import BioscopeRestarts, {
    BioscopeRestartsProps,
} from 'components/molecules/BioscopeRestarts/BioscopeRestarts';
import { CurrentBioscopePlacement } from 'components/molecules/CurrentBioscopePlacement/CurrentBioscopePlacement';
import NoData from 'components/NoData';

import styles from './BioscopeDetail.module.scss';

interface BioscopeDetailProps {
    isBioscopeDetailLoading: boolean;
    isOptoscaleAdmin: boolean;
    bioscope: BioscopeDetailTypes;

    deleteLastBioscopeMove?: () => void;
    moveBioscope?: () => void;
    bioscopeRestarts: BioscopeRestartsProps;
    bioscopeRouterRestarts: BioscopeRestartsProps;

    queryTypes?: {
        CAGE?: string;
        BIOSCOPE?: string;
        UNCLASSIFIED?: string;
    };
}

const BioscopeDetail = ({
    bioscopeRestarts,
    bioscopeRouterRestarts,
    isBioscopeDetailLoading,
    bioscope,
    deleteLastBioscopeMove,
    isOptoscaleAdmin,
    moveBioscope,
    queryTypes,
}: BioscopeDetailProps) => {
    const { t } = useTranslation();

    if (isBioscopeDetailLoading) {
        return <Loader />;
    }

    if (!bioscope) {
        return <NoData />;
    }

    const { client, isConnected, subscribe, publish } = useMqtt();
    const [bioscopeData, setbioscopeData] = useState<any>(null);
    const [configData, setConfigData] = useState<any>(null);
    const [isOnline, setIsOnline] = useState<boolean>(false);
    const [heartbeatTimeout, setHeartbeatTimeout] = useState<NodeJS.Timeout | null>(null);

    const handleOfflineTimeout = useCallback(() => {
        console.log('No heartbeat message received in 5 minutes, setting offline.');
        setIsOnline(false);
    }, []);

    const resetHeartbeatTimeout = useCallback(() => {
        // Clear the previous timer
        if (heartbeatTimeout) {
            clearTimeout(heartbeatTimeout);
        }

        // Set a new timer for 5 minutes
        const timeout = setTimeout(handleOfflineTimeout, 2 * 60 * 1000); // 5 minutes in milliseconds
        setHeartbeatTimeout(timeout);
    }, [heartbeatTimeout, handleOfflineTimeout]);

    const messageHandler = useCallback((topic: string, message: Buffer) => {
        try {
            const parsedMessage = JSON.parse(message.toString());
            console.log(`Received message from ${topic}:`, parsedMessage);
            if (topic.includes('data')) {
                setbioscopeData(parsedMessage);
            } else if (topic.includes('config')) {
                setConfigData(parsedMessage);
            } else if (topic.includes('heartbeat')) {
                const { status, timestamp } = parsedMessage;

                if (!timestamp || !status) {
                    console.warn('Invalid message format: missing status or timestamp.');
                    return;
                }

                const messageTime = new Date(timestamp); // Timestamp is in UTC
                if (isNaN(messageTime.getTime())) {
                    console.warn('Invalid timestamp format in heartbeat message.');
                    return;
                }

                const currentTime = new Date();
                const timeDifference = (currentTime.getTime() - messageTime.getTime()) / 1000 / 60; // in minutes

                if (status === 'active' && timeDifference <= 5) {
                    setIsOnline(true);
                    resetHeartbeatTimeout(); // Reset the timeout on receiving a valid message
                }
            }
        } catch (error) {
            console.error(`Error parsing message from ${topic}:`, error);
        }
    }, []);

    useEffect(() => {
        if (isConnected && bioscope?.id) {
            const topics = [
                `bioscope/data/response/${bioscope.id}`,
                `bioscope/config/response/${bioscope.id}`,
                `bioscope/heartbeat/${bioscope.id}`,
            ];

            for (const topic of topics) {
                subscribe(topic, { qos: 1 });
                console.log(`Subscribed to topic: ${topic}`);
            }

            client?.on('message', messageHandler);

            return () => {
                if (heartbeatTimeout) clearTimeout(heartbeatTimeout); // Clear timeout on unmount
                client?.off('message', messageHandler);
            };
        }
    }, [isConnected, bioscope?.id, subscribe, client, messageHandler]);

    const handlePublish = useCallback(
        (topic: string, message: string) => {
            if (client && isConnected) {
                publish(topic, message, { qos: 1 });
            }
        },
        [client, isConnected]
    );

    return (
        <Container className={styles.bioscopeDetail}>
            {/* <PageTitle showH1={true} title={`${t('Bioscope')} ID: ${bioscope.id}`} /> */}
            <div className={styles.titleWithLed}>
                <h2 className={styles.statusTitle}>
                    {t('Bioscope status')}: {isOnline ? t('Online') : t('Offline')}
                </h2>
                <div className={styles.ledWidget}>
                    <div
                        className={isOnline ? styles.ledGreen : styles.ledRed}
                        title={isOnline ? t('Online') : t('Offline')}
                    />
                </div>
            </div>

            <div className={styles.bioscopeDetailPanelWrapper}>
                <CurrentBioscopePlacement
                    loading={false}
                    error={false}
                    errorText="Error updating bioscope move data"
                    title="Placed With"
                    client={bioscope.client}
                    deleteLastBioscopeMove={deleteLastBioscopeMove}
                    moveBioscope={moveBioscope}
                />
                <LastPlacement title="Last placement" lastLocation={bioscope.lastLocation} />
                <LastMeasurement lastMeasurement={bioscope.lastMeasurement} />
            </div>
            <div className={styles.bioscopeDetailPanelWrapper}>
                <BioscopeMeta
                    bioscopeMetaData={bioscope.version}
                    isLoading={isBioscopeDetailLoading}
                />
                {queryTypes && (
                    <div className={styles.BioscopeImageCardContainer}>
                        <BioscopeImageCardContainer bioscope={bioscope} />
                    </div>
                )}
            </div>

            {isOptoscaleAdmin && (
                <div className={styles.bioscopeDetailPanelWrapper}>
                    <BioscopeConfiguration
                        bioscope={bioscope}
                        configData={configData}
                        handlePublish={handlePublish}
                    />
                    <BioscopeSystemReadings
                        bioscope={bioscope}
                        bioscopeData={bioscopeData}
                        handlePublish={handlePublish}
                    />
                    <WinchControl
                        bioscope={bioscope}
                        bioscopeData={bioscopeData}
                        handlePublish={handlePublish}
                    />
                </div>
            )}
            <div className={styles.bioscopeDetailPanelWrapperFullWidth}>
                <BioscopeConnection connection={bioscope.connection} />
            </div>
            <div className={styles.bioscopeDetailPanelWrapperFullWidth}>
                <Box title={t('Bioscope restarts')}>
                    <BioscopeRestarts {...bioscopeRestarts} />
                </Box>
            </div>
            <div className={styles.bioscopeDetailPanelWrapperFullWidth}>
                <Box title={t('Bioscoperouter  restarts')}>
                    <BioscopeRestarts {...bioscopeRouterRestarts} />
                </Box>
            </div>

            <div className={styles.bioscopeDetailPanelWrapperFullWidth}>
                <BioscopePosition bioscopeLocations={bioscope.locations} />
            </div>
        </Container>
    );
};

export default BioscopeDetail;
export { BioscopeDetail };
