import React, { useState } from 'react';
import { t } from 'i18next';

import styles from './PillSwitch.module.scss';

type WeekNumberObject = {
    weekNumber: number;
    year: number;
    interimReport: boolean;
    key: string;
};

export interface PillSwitchProps {
    text?: string;
    value?: string;
    toggleHandler?: (value: string, isOn: boolean) => void;
    active?: boolean;
    children?: React.ReactNode;
}

const PillSwitch = ({
    children,
    text = '',
    value,
    active = false,
    toggleHandler = () => {},
}: PillSwitchProps) => {
    const [isOn, setIsOn] = useState<boolean>(active.valueOf());

    const internalToggleHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        const value = event.currentTarget.value;
        const newIsOn = !isOn;
        setIsOn(newIsOn);
        toggleHandler(value, newIsOn);
    };

    return (
        <div className={styles.pillswitch}>
            <button
                type="button"
                className={isOn ? styles.active : ''}
                onClick={(event) => internalToggleHandler(event)}
                value={value}>
                <span>
                    <span>{children}</span>
                    <span>{text}</span>
                </span>
            </button>
        </div>
    );
};

export interface PillSwitchWrapperProps {
    onPillListChange?: (pillDataObjects: WeekNumberObject[]) => void;
    pillDataObjects: WeekNumberObject[];
}
const PillSwitchWrapper = ({
    pillDataObjects = [],
    onPillListChange = () => {},
}: PillSwitchWrapperProps) => {
    const { weekNumber, year, interimReport, key } = pillDataObjects[0];
    const [selectedWeeks, setSelectedWeeks] = useState<WeekNumberObject[]>([
        { weekNumber, year, interimReport, key },
    ]);

    const listHandler = (value: string, isOn: boolean) => {
        if (isOn === true) {
            const weekAdded = pillDataObjects.find((week) => week.key === value);
            if (!weekAdded) return;
            const weeks = [...selectedWeeks, weekAdded];
            setSelectedWeeks([...weeks]);
            onPillListChange([...weeks]);
        } else {
            const weekRemoved = selectedWeeks.filter((week) => week.key !== value);
            setSelectedWeeks([...weekRemoved]);
            onPillListChange([...weekRemoved]);
        }
    };

    const formatPillText = (pillDataObject: WeekNumberObject) => {
        const pillText =
            new Date().getFullYear() !== pillDataObject.year
                ? `${pillDataObject.weekNumber}/${pillDataObject.year}`
                : `${pillDataObject.weekNumber}`;

        const weekText = pillDataObject.interimReport ? `${pillText} (I)` : pillText;
        return `${t('Week')} ${weekText}`;
    };

    return (
        <div className={styles.pillswitchwrapper}>
            {pillDataObjects.map((pillDataObject) => (
                <PillSwitch
                    key={`${pillDataObject.weekNumber}${pillDataObject.year}`}
                    toggleHandler={listHandler}
                    active={
                        selectedWeeks.find((week) => week.key === pillDataObject.key) ? true : false
                    }
                    text={formatPillText(pillDataObject)}
                    value={`${pillDataObject.weekNumber}-${pillDataObject.year}`}
                />
            ))}
        </div>
    );
};

export default PillSwitch;
export { PillSwitch, PillSwitchWrapper };
