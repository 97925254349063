import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton, IconButtonProps } from '@/components/atoms/Buttons';
import ChevronDirectionDownIcon from '@/components/atoms/icons/ChevronDirectionDownIcon';

import DropDownPanel from './DropDownPanel';

import styles from './DropDown.module.scss';

export interface OptoDropDownProps {
    children?: React.ReactNode;
    open?: boolean;
    name?: string;
    placeholder?: string;
    id: string;
    dynamicWidth?: boolean;
    onChangeHandler?: (selectedOption: SelectedOption) => void;
    selected?: SelectedOption | null;
    disabled?: boolean;
}

export interface DropDownItemProps {
    children: React.ReactNode;
    open: boolean;
}

type SelectedOption = { value: string; text: string };

const OptoDropDown = ({
    children = <></>,
    open = false,
    name = '',
    placeholder = 'Please choose an option',
    dynamicWidth = false,
    selected = null,
    disabled = false,
    onChangeHandler = () => {},
}: OptoDropDownProps) => {
    const { t } = useTranslation();
    const [panelOpen, setPanelOpen] = useState<boolean>(open);
    const [currentSelected, setCurrentSelected] = useState<SelectedOption | null>({
        value: 'default',
        text: name ? `-- ${t(name.toLowerCase())} --` : `-- ${t(placeholder)} --`,
    });
    const [initialWidth, setInitialWidth] = useState<number | null>(null);

    const dropDownCallback = useCallback(
        (node: HTMLDivElement) => {
            if (node && dynamicWidth) {
                const buttons = node.querySelectorAll('button, a[role="button"]');
                const elementWidths = Array.from(buttons).map(
                    (button) => (button as HTMLElement).offsetWidth
                );
                setInitialWidth(Math.max(...elementWidths));
            }
        },
        [dynamicWidth]
    );

    useEffect(() => {
        if (selected) {
            setCurrentSelected(
                selected.value === 'default'
                    ? {
                          value: 'default',
                          text: name ? `-- ${t(name.toLowerCase())} --` : `-- ${t(placeholder)} --`,
                      }
                    : selected
            );
        }
    }, [selected, name, placeholder, t]);

    const handleCancelSelection = () => {
        const defaultOption = {
            value: 'default',
            text: name ? `-- ${t(name.toLowerCase())} --` : `-- ${t(placeholder)} --`,
        };
        setCurrentSelected(defaultOption);
        setPanelOpen(false);
        onChangeHandler(defaultOption);
    };

    const handlePanelItemClick = (event: React.MouseEvent<HTMLDivElement>) => {
        const target = event.target as HTMLElement;
        const selectedOption = {
            value: target.dataset.value as string,
            text: target.textContent as string,
        };
        setCurrentSelected(selectedOption);
        setPanelOpen(false);
        onChangeHandler(selectedOption);
    };

    return (
        <div
            className={styles.dropdown}
            style={{ width: initialWidth ? `${initialWidth}px` : 'auto' }}
            data-open={panelOpen && !disabled}
            ref={dropDownCallback}>
            <OptoDropDownOption
                selected={currentSelected?.value !== 'default'}
                iconPos="right"
                onClick={() => setPanelOpen(!panelOpen)}
                variant="table"
                value={currentSelected?.value || 'default'}
                name="default"
                buttonText={t(currentSelected?.text || '')}>
                <ChevronDirectionDownIcon />
            </OptoDropDownOption>
            <DropDownPanel selectOptionHandler={handlePanelItemClick} open={panelOpen}>
                <IconButton
                    onClick={handleCancelSelection}
                    variant="table"
                    value=""
                    buttonText={t(currentSelected?.text || '')}
                />
                {children}
            </DropDownPanel>
        </div>
    );
};

export interface DropDownOptionItemProps extends IconButtonProps {
    selected: boolean;
}

const AsOptoDropDownOption = (IconButton: React.ComponentType<IconButtonProps>) => {
    const WrappedComponent = (props: DropDownOptionItemProps) => {
        const handleSelectOption = (event: React.MouseEvent<HTMLDivElement>) => {
            const element = event.target as HTMLElement;
            const customEvent = new CustomEvent('selected', {
                detail: { ...props },
                bubbles: true,
                composed: true,
            });
            element.dispatchEvent(customEvent);
        };

        return (
            // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
            <div
                data-selected={props.selected}
                onClick={handleSelectOption}
                className={styles.optionWrapperComponent}>
                <IconButton {...props} variant="table" />
            </div>
        );
    };

    WrappedComponent.displayName = 'AsOptoDropDownOption';

    return WrappedComponent;
};

const OptoDropDownOption = AsOptoDropDownOption(IconButton);

export default OptoDropDown;
export { OptoDropDown, OptoDropDownOption };
