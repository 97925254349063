export interface CalulateAverage {
    cages: {
        count: number;
        depthAvg: number;
        depthMax: number;
        depthMin: number;
        femaleAvg: number;
        caligusElongatusAvg: number;
        movingStageAvg: number;
        livingWeight: number;
        femaleTotal?: number;
        caligusElongatusTotal?: number;
        movingStageTotal?: number;
        isIncluded: boolean;
    }[];
}

export interface AverageResult {
    count: number;
    depthAvg: number;
    depthMax: number;
    depthMin: number;
    femaleAvg: number;
    caligusElongatusAvg: number;
    movingStageAvg: number;
    livingWeight: number;
    femaleTotal: number;
    caligusElongatusTotal: number;
    movingStageTotal: number;
    name?: string;
}

const calculateAverage = ({ cages }: CalulateAverage): AverageResult => {
    const includedCages = cages.filter((c) => c.isIncluded);
    // neglecting undefined depth while calculating average of depth
    // because of defective depth sensor
    const depthAvgDivider = includedCages.filter((c) => c.depthAvg).length;
    return includedCages.reduce(
        (res, curr) => {
            if (!curr.isIncluded) return { ...res };
            return {
                count: res.count + curr.count,
                depthAvg: res.depthAvg + curr.depthAvg / depthAvgDivider,
                depthMax: res.depthMax + curr.depthMax / depthAvgDivider,
                depthMin: res.depthMin + curr.depthMin / depthAvgDivider,
                femaleAvg: res.femaleAvg + curr.femaleAvg / includedCages.length,
                caligusElongatusAvg:
                    res.caligusElongatusAvg + curr.caligusElongatusAvg / includedCages.length,
                movingStageAvg: res.movingStageAvg + curr.movingStageAvg / includedCages.length,
                livingWeight: res.livingWeight + curr.livingWeight / includedCages.length,
                femaleTotal: res.femaleTotal + curr.femaleTotal,
                caligusElongatusTotal: res.caligusElongatusTotal + curr.caligusElongatusTotal,
                movingStageTotal: res.movingStageTotal + curr.movingStageTotal,
            };
        },
        {
            count: 0,
            depthAvg: 0,
            depthMax: 0,
            depthMin: 0,
            femaleAvg: 0,
            caligusElongatusAvg: 0,
            movingStageAvg: 0,
            livingWeight: 0,
            femaleTotal: 0,
            caligusElongatusTotal: 0,
            movingStageTotal: 0,
        }
    );
};

export default calculateAverage;
export { calculateAverage };
