import moment from 'moment';

export const niceDateTime = (dateToFormat) => moment(dateToFormat).format('D. MMM YYYY HH:mm');
export const evenNicerDateTime = (dateToFormat) =>
    moment(dateToFormat).format('HH:mm, MM YYYY HH:mm');
export const niceDate = (date) => moment(date).format('DD.MM.YYYY');

// TODO - Make this locale dynamic
export interface localizedDateFormatProps {
    dateString: string;
    locale?: string;
    onlydate?: boolean;
}

export function localizedDateFormat({
    dateString,
    locale = navigator.language,
    onlydate = false,
}): string {
    if (!dateString) {
        return '';
    }

    try {
        return new Intl.DateTimeFormat(locale, {
            dateStyle: 'short',
            timeStyle: onlydate ? undefined : 'short',
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        }).format(new Date(dateString));
    } catch (error) {
        return new Date(dateString).toString();
    }
}

export function localizedDate(dateString: string, locale: string = navigator.language): string {
    return localizedDateFormat({ dateString, locale, onlydate: true });
}

export function localizedDateTime(dateString: string, locale: string = navigator.language): string {
    return localizedDateFormat({ dateString, locale });
}

export default {
    niceDateTime,
    evenNicerDateTime,
    niceDate,
    localizedDateFormat,
    localizedDate,
    localizedDateTime,
};
