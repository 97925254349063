import LiceIcon from '../../../../../src/assets/InternalIcons/LiceIcon.svg';
import ArrowWithLineDown from '../../../../../src/assets/MingCute-main/svg/arrow/align_arrow_down_fill.svg';
import ArrowWithLineLeft from '../../../../../src/assets/MingCute-main/svg/arrow/align_arrow_left_fill.svg';
import ArrowWithLineRight from '../../../../../src/assets/MingCute-main/svg/arrow/align_arrow_right_fill.svg';
import ArrowWithLineUp from '../../../../../src/assets/MingCute-main/svg/arrow/align_arrow_up_fill.svg';
import ArrowRight from '../../../../../src/assets/MingCute-main/svg/arrow/arrow_right_line.svg';
import WinchIcon from '../../../../../src/assets/MingCute-main/svg/arrow/move_fill.svg';
import MoveBioscopeIcon from '../../../../../src/assets/MingCute-main/svg/arrow/transfer_4_fill.svg';
import CompareIcon from '../../../../../src/assets/MingCute-main/svg/arrow/transfer_fill.svg';
import TrendIconDown from '../../../../../src/assets/MingCute-main/svg/arrow/trending_down_fill.svg';
import TrendIconUp from '../../../../../src/assets/MingCute-main/svg/arrow/trending_up_fill.svg';
import HomeIcon from '../../../../../src/assets/MingCute-main/svg/building/home_3_fill.svg';
import GrowthIcon from '../../../../../src/assets/MingCute-main/svg/business/chart_line_fill.svg';
import ChecvronLineIcon from '../../../../../src/assets/MingCute-main/svg/chevron/chevron_line.svg';
import FeedBackIcon from '../../../../../src/assets/MingCute-main/svg/contact/message_4_line.svg';
import LayerIcon from '../../../../../src/assets/MingCute-main/svg/design/layer_line.svg';
import DashboardIcon from '../../../../../src/assets/MingCute-main/svg/design/layout_grid_fill.svg';
import Online from '../../../../../src/assets/MingCute-main/svg/device/wifi_line.svg';
import OfflineIcon from '../../../../../src/assets/MingCute-main/svg/device/wifi_off_line.svg';
import CopyIcon from '../../../../../src/assets/MingCute-main/svg/file/copy_fill.svg';
import DownloadFile from '../../../../../src/assets/MingCute-main/svg/file/download_2_line.svg';
import SearchIcon from '../../../../../src/assets/MingCute-main/svg/file/search_2_fill.svg';
import ZoomInIcon from '../../../../../src/assets/MingCute-main/svg/file/zoom_in_line.svg';
import ResetZoom from '../../../../../src/assets/MingCute-main/svg/file/zoom_out_line.svg';
import ExitFullScreen from '../../../../../src/assets/MingCute-main/svg/media/fullscreen_exit_line.svg';
import FullScreen from '../../../../../src/assets/MingCute-main/svg/media/fullscreen_line.svg';
import VideoCameraIcon from '../../../../../src/assets/MingCute-main/svg/media/video_camera_2_fill.svg';
import FirstAidIcon from '../../../../../src/assets/MingCute-main/svg/other/first_aid_kit_fill.svg';
import AlertOctagon from '../../../../../src/assets/MingCute-main/svg/system/alert_octagon_fill.svg';
import CloseIcon from '../../../../../src/assets/MingCute-main/svg/system/close_line.svg';
import ElipsisIcon from '../../../../../src/assets/MingCute-main/svg/system/more_2_fill.svg';
import HelpIcon from '../../../../../src/assets/MingCute-main/svg/system/question_fill.svg';
import LaksvelShield from '../../../../../src/assets/MingCute-main/svg/system/safe_shield_2_fill.svg';
import SettingsIcon from '../../../../../src/assets/MingCute-main/svg/system/settings_6_line.svg';
import ShareIcon from '../../../../../src/assets/MingCute-main/svg/system/share_2_line.svg';
import OnTheMoveIcon from '../../../../../src/assets/MingCute-main/svg/transport/rudder_line.svg';
import UserIcon from '../../../../../src/assets/MingCute-main/svg/user/group_3_fill.svg';

export const ArrowWithLineDownIconSVG = ArrowWithLineDown;
export const ArrowWithLineUpIconSVG = ArrowWithLineUp;
export const ArrowWithLineLeftIconSVG = ArrowWithLineLeft;
export const ArrowWithLineRightIconSVG = ArrowWithLineRight;
export const HomeIconSVG = HomeIcon;
export const UserIconSVG = UserIcon;
export const HelpIconSVG = HelpIcon;
export const MoveBioscopeIconSVG = MoveBioscopeIcon;
export const LiceIconSVG = LiceIcon;
export const DashboardIconSVG = DashboardIcon;
export const CompareIconSVG = CompareIcon;
export const SearchIconSVG = SearchIcon;
export const ArrowRightSVG = ArrowRight;
export const AlertOctagonSVG = AlertOctagon;
export const TrendUpSVG = TrendIconUp;
export const TrendDownSVG = TrendIconDown;
export const VideoCameraIconSVG = VideoCameraIcon;
export const WinchIconSVG = WinchIcon;
export const SettingsIconSVG = SettingsIcon;

export const LaksvelShieldSVG = LaksvelShield;
export const GrowthIconSVG = GrowthIcon;
export const FishHealthIconSVG = FirstAidIcon;
export const ElipsisIconSVG = ElipsisIcon;
export const CopyIconSVG = CopyIcon;
export const ResetZoomIconSVG = ResetZoom;
export const DownloadFileIconSVG = DownloadFile;
export const FullScreenIconSVG = FullScreen;
export const FullScreenExitconSVG = ExitFullScreen;
export const CloseIconSVG = CloseIcon;
export const OnTheMoveIconSVG = OnTheMoveIcon;
export const OfflineIconSVG = OfflineIcon;
export const OnlineIconSVG = Online;
export const ShareIconSVG = ShareIcon;
export const FeedBackIconSVG = FeedBackIcon;
export const LayerIconSVG = LayerIcon;
export const ZoomInIconSVG = ZoomInIcon;
export const ChecvronLineIconSVG = ChecvronLineIcon;
