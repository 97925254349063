import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './BlockTitle.module.scss';

export interface BlockTitleProps {
    heading: string;
    color?: 'default' | 'primary' | 'secondary';
    level?: 'h2' | 'h3' | 'h4';
}

const BlockTitle = ({ heading, color = 'default', level = 'h3' }: BlockTitleProps) => {
    const { t } = useTranslation();
    const HeadingTag = level;

    return (
        <div className={styles.blocktitle} data-color={color}>
            <HeadingTag>{t(heading)}</HeadingTag>
        </div>
    );
};

export default BlockTitle;
export { BlockTitle };
