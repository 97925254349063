import React from 'react';

import styles from './GenericIcon.module.scss';

export interface GenericIconProps {
    icon?: string;
    size?: number;
    color?: string;
}

const GenericIcon = ({ icon = '', size = 25, color = 'black' }: GenericIconProps) => {
    const iconUrl = typeof icon === 'string' ? icon.replace('/public', '') : icon;
    return (
        <figure
            className={styles.genericiconcontainer}
            data-type="generic-icon"
            style={{
                backgroundColor: color,
                maskImage: `url(${iconUrl})`,
                height: size,
                width: size,
            }}
        />
    );
};

export default GenericIcon;
export { GenericIcon };
