import React from 'react';

import styles from './UmerBoxElementLeftRightInfoSimple.module.scss';

export interface UmerBoxElementLeftRightInfoSimpleProps {
    leftSide?: string | React.ReactNode;
    rightSide?: string | React.ReactNode;
    icon?: React.ReactNode;
}

const UmerBoxElementLeftRightInfoSimple = ({
    leftSide = '',
    rightSide = '',
    icon = <></>,
}: UmerBoxElementLeftRightInfoSimpleProps) => {
    return (
        <div className={styles.umerBoxElementLeftRightInfoSimple}>
            <div className={styles.leftSideInfoText}>{leftSide}</div>
            <div className={styles.rightSideInfoText}>
                <div className={styles.rightIconContainer}>{icon}</div>
                {rightSide}
            </div>
        </div>
    );
};

export default UmerBoxElementLeftRightInfoSimple;
export { UmerBoxElementLeftRightInfoSimple };
