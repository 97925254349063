import React from 'react';

import styles from './UmerBoxElementHeader.module.scss';

export interface UmerBoxElementHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
    children?: React.ReactNode;
    rightSideContent?: React.ReactNode;
    headerText?: string;
    description?: string | React.ReactNode;
    level?: 'h2' | 'h3' | 'h4';
}

const UmerBoxElementHeader = ({
    children = <></>,
    rightSideContent = <></>,
    headerText = '',
    description = '',
    level = 'h4',
}: UmerBoxElementHeaderProps) => {
    const HeadingTag = level;
    return (
        <div className={styles.umerBoxElementHeader}>
            <div className={styles.topPartContainer}>
                <HeadingTag>{headerText}</HeadingTag>
                <div>
                    {rightSideContent}
                    {children}
                </div>
            </div>
            <div className={styles.description}>{description}</div>
        </div>
    );
};

export default UmerBoxElementHeader;
export { UmerBoxElementHeader };
