import { t } from 'i18next';
import {
    useClientController_AllLiceWeeklyReport,
    useClientController_Overview,
} from 'services/hooks';

import ToastInfoTemplate from '@/components/molecules/ToastTemplate';
import { useMe } from '@/contexts/meContext';
import { OverviewLocation } from '@/services/types';
import { Loader } from 'components/atoms/Loader';
import SomethingHappened from 'components/SomethingHappened';

import LocationLiceReportPageContent from './LocationLiceReportPageContent';
interface LocationLiceReportProps {
    clientId: number;
    locationId: number;
}

const LiceReportContainer = ({ clientId, locationId }: LocationLiceReportProps) => {
    const { state } = useMe();

    const client = state.clients.find((client) => client.id === clientId);

    const { isLoading, data, error } = useClientController_AllLiceWeeklyReport(clientId);

    const {
        isLoading: isLoadingClientData,
        data: clientData,
        error: clientDataError,
        refetch,
    } = useClientController_Overview(clientId, {
        staleTime: 5 * 60 * 1000,
        cacheTime: 5 * 60 * 1000,
    });

    ToastInfoTemplate({
        content: t('Lice report missing pen explain'),
        page: 'lice-report',
        position: 'top-right',
        icon: 'lice',
        type: 'info',
    });
    if (isLoading || isLoadingClientData) {
        return <Loader />;
    }

    if (error || clientDataError) {
        return <SomethingHappened />;
    }

    return (
        <LocationLiceReportPageContent
            clientId={clientId}
            clientName={client?.name}
            reports={data.data?.filter((report) =>
                report.locations.find((location) => location.id === locationId)
            )}
            locationId={locationId}
            overviewLocation={
                clientData?.data?.locations?.find((x) => x.id === locationId) as OverviewLocation
            }
        />
    );
};

export default LiceReportContainer;
export type { LocationLiceReportProps };
export { LiceReportContainer };
