import React from 'react';

import styles from './DropDownPanel.module.scss';

export interface DropDownPanelProps {
    children?: React.ReactNode;
    open?: boolean;
}

const DropDownPanel = ({ children, open = false }: DropDownPanelProps) => {
    return (
        <div className={styles.dropdownpanel} data-open={open}>
            <div>{children}</div>
        </div>
    );
};

export default DropDownPanel;
export { DropDownPanel };
