import React from 'react';
import { useTranslation } from 'react-i18next';
import { graphSettings, settings } from 'config/settings';
import { GrowthValue, LaksvelValue, LiceValue, WoundsValue } from 'helpers/textValueCalculator';

import {
    FishHealthIconSVG,
    GrowthIconSVG,
    LaksvelShieldSVG,
    LiceIconSVG,
} from '@/components/atoms/icons/GenericIcon/GenericIcons';
import { GenericInfoBox } from '@/components/molecules/GenericInfoBox';
import { LocationsLicenseOverview, OverviewLocation } from '@/services/types';

import styles from './AverageHeader.module.scss';

export interface AverageHeaderProps {
    children?: React.ReactNode;
    location: OverviewLocation;
    locationLicense?: LocationsLicenseOverview;
    isOptoscaleAdmin?: boolean;
}

interface FishTypeColors<T> {
    [key: string]: T;
}

const AverageHeader = ({ location, locationLicense, isOptoscaleAdmin }: AverageHeaderProps) => {
    const { t } = useTranslation();
    const hasAccessToFishHealth =
        isOptoscaleAdmin || (locationLicense?.licenses.fishHealthLicense.hasValidLicense ?? false);
    const hasAccessToLice =
        isOptoscaleAdmin || (locationLicense?.licenses.liceLicense.hasValidLicense ?? false);
    const hasAccessToLaksvel =
        isOptoscaleAdmin || (locationLicense?.licenses.LaksvelLicense.hasValidLicense ?? false);
    const { fishTypes } = location;
    const color = (graphSettings.fishTypeName as FishTypeColors<string>)[
        fishTypes.name.toLowerCase()
    ];

    return (
        <div className={`${styles.averageheader}`} style={{ color: color }}>
            <GenericInfoBox
                info={GrowthValue(location.averages.growth, t('daypartTag.day'))}
                title={t('Growth')}
                icon={GrowthIconSVG}
                circleColor={settings.purpleColor}
                grayBackground={true}
                tooltipText={'Growth test tooltip'}
                shouldHaveTooltip={false}
            />

            {hasAccessToLice ? (
                <GenericInfoBox
                    info={LiceValue(location.averages.femaleLice)}
                    title={t('Lice')}
                    icon={LiceIconSVG}
                    circleColor={settings.orangeColor}
                    grayBackground={true}
                    tooltipText={'Lice test tooltip'}
                    shouldHaveTooltip={false}
                />
            ) : (
                ''
            )}

            {hasAccessToLaksvel ? (
                <GenericInfoBox
                    info={LaksvelValue(location.averages?.laksvel)}
                    title={t('Laksvel')}
                    icon={LaksvelShieldSVG}
                    circleColor={settings.blueColor}
                    grayBackground={true}
                    tooltipText={'Laksvel test tooltip'}
                    shouldHaveTooltip={false}
                />
            ) : (
                ''
            )}

            {hasAccessToFishHealth && location.fishTypes.shortName !== 'trout' ? (
                <GenericInfoBox
                    info={WoundsValue(location?.averages?.wounds)}
                    title={t('Wound')}
                    icon={FishHealthIconSVG}
                    circleColor={settings.primaryColor}
                    grayBackground={true}
                    tooltipText={'Wound test tooltip'}
                    shouldHaveTooltip={false}
                />
            ) : (
                ''
            )}
        </div>
    );
};

export default AverageHeader;
export { AverageHeader };
