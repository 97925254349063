import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import cn from 'classnames/bind';

import { Button } from 'components/atoms/Buttons/Button';

import styles from './style.module.scss';

const cx = cn.bind(styles);

export interface SmallDropDownItem {
    className?: string;
    dropDownClassname?: string;
    items: Array<{
        label: string;
        value: string;
        title?: string;
        disabled?: boolean;
    }>;
    value: string;
    onChange: (value: string) => void;
    width?: string;
    scrollable?: boolean;
    title?: string;
    disabled?: boolean;
    buttonElipsis?: boolean;
}

const SmallDropDown = ({
    className = undefined,
    dropDownClassname = undefined,
    items,
    value,
    onChange,
    width = '150px',
    scrollable = false,
    title = 'Toggle',
    disabled = false,
    buttonElipsis = true,
}: SmallDropDownItem) => {
    const [isOpen, setIsOpen] = useState(false);
    const selectedItem = items.find((item) => item.value === value);
    const { t } = useTranslation();

    return (
        <div
            className={cx(className, {
                container: true,
                shadow: isOpen,
            })}>
            <Button
                className={styles.toggle}
                onClick={() => !disabled && setIsOpen(!isOpen)}
                disabled={disabled}>
                <div className={classnames(styles.titleText)}>
                    {t(selectedItem ? selectedItem.label : title)}
                </div>
                <i
                    className={cn.bind(styles, {
                        fas: true,
                        'fa-caret-up': isOpen,
                        'fa-caret-down': !isOpen,
                    })()}
                />
            </Button>
            <div
                className={cx(dropDownClassname, {
                    menu: true,
                    visible: isOpen,
                    hidden: !isOpen,
                    shadow: isOpen,
                    scrollable: scrollable,
                })}>
                {items.map((item, index) => (
                    <button
                        type="button"
                        data-type="dropdownButton"
                        className={cx({
                            item: true,
                            buttonElipsis: buttonElipsis,
                        })}
                        key={index}
                        onClick={() => {
                            onChange(item.value);
                            setIsOpen(false);
                        }}
                        title={item.title}
                        disabled={item.disabled}>
                        {t(item.label)}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default SmallDropDown;
export { SmallDropDown };
