import React from 'react';

import styles from './DropDown.module.scss';

export interface DropDownPanelProps {
    children?: React.ReactNode;
    open?: boolean;
    selectOptionHandler?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const DropDownPanel = ({
    children,
    open = false,
    selectOptionHandler = () => {},
}: DropDownPanelProps) => {
    return (
        <div className={styles.dropdownpanel} data-open={open}>
            {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
            <div onClick={selectOptionHandler}>{children}</div>
        </div>
    );
};

export default DropDownPanel;
export { DropDownPanel };
