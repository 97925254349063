import React from 'react';
import { Spinner } from 'reactstrap';

import { DownloadIcon } from '@/components/atoms/icons';
import { IconButton } from 'components/atoms/Buttons';

import { useDownloadCSVXLSX } from '../../../hooks/download-CSV-XLSX';

interface DownloadCsvXlsxProps {
    label: string;
    data: any[];
    nameToInclude?: string;
    COLUMNS?: any[];
    variant?: 'primary' | 'secondary' | 'danger' | 'table';
    buttonSize?: 'small' | 'medium' | 'large';
}

const DownloadCsvXlsx = ({
    label,
    data,
    variant = 'secondary',
    nameToInclude = undefined,
    COLUMNS = [],
    buttonSize = 'medium',
}: DownloadCsvXlsxProps) => {
    const { loadingCSV, loadingXLSX, downloadCSV, downloadXLSX } = useDownloadCSVXLSX({
        data,
        nameToInclude,
        COLUMNS,
    });
    /* { label, data } */
    if (label === 'CSV') {
        return (
            <IconButton
                size={buttonSize}
                variant={variant}
                buttonText={label}
                onClick={() => downloadCSV()}>
                {loadingCSV ? <Spinner size="sm" /> : <DownloadIcon size={20} />}
            </IconButton>
        );
    }
    return (
        <IconButton
            size={buttonSize}
            variant={variant}
            onClick={() => downloadXLSX()}
            buttonText={label}>
            {loadingXLSX ? <Spinner size="sm" /> : <DownloadIcon size={20} />}
        </IconButton>
    );
};

export default DownloadCsvXlsx;
export { DownloadCsvXlsx };
