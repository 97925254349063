import calculateAverage, { AverageResult } from '@/utils/calculateCageAverages';
import { localeFormatNumber } from '@/utils/decimalNumberFormatter';

import { LocationsIncluded } from '../RegionLiceReportPageContent';

/**
 * Helper function to format the cage data for the PDF
 */
const formatCage = (cage: AverageResult, locationName: string, locale: string) => {
    const dataWithLice = {
        location_name: locationName,
        pen_name: cage?.name,
        female_avg: localeFormatNumber(cage?.femaleAvg, 2, locale),
        female_total: cage?.femaleTotal?.toFixed(0) ?? '',
        moving_stage_avg: localeFormatNumber(cage?.movingStageAvg, 2, locale),
        moving_stage_total: cage?.movingStageTotal?.toFixed(0) ?? '',
        caligus_elongatus_avg: localeFormatNumber(cage?.caligusElongatusAvg, 2, locale),
        caligus_elongatus_total: cage?.caligusElongatusTotal?.toFixed(0) ?? '',
        count: cage?.count ?? '',
        living_weight: `${cage?.livingWeight?.toFixed(0)} g` ?? '',
        depth_avg: localeFormatNumber(cage?.depthAvg, 1, locale),
    };

    return { ...dataWithLice };
};

/**
 *
 * @param param0 The Container component preparing the data for the PDF
 */

interface DownloadPDFContainerProps {
    locationsIncluded: LocationsIncluded[];
    weekNumber: string;
    clientName: string;
    locale?: string;
}

const LocationPreparePDFdata = ({
    locationsIncluded,
    clientName,
    weekNumber,
    locale = 'en',
}: DownloadPDFContainerProps) => {
    const filteredCagesLocation = locationsIncluded.map((location) => ({
        name: location.name,
        id: location.id,
        cages: location.cages.filter((cage) => cage.isIncluded),
    }));

    const allData =
        filteredCagesLocation?.flatMap((location) => {
            const result = location.cages.flatMap((cage) => {
                if (cage.isIncluded) {
                    return formatCage(cage as unknown as AverageResult, location.name, locale);
                }
            });

            //calculating average of each Location
            const average = calculateAverage({ cages: location.cages });

            const formattedAverage = formatCage(average, location.name, locale);

            formattedAverage.count = average.count;

            return [
                ...result,
                formattedAverage,
                {
                    location_name: '',
                    pen_name: '',
                    female_avg: '',
                    female_total: '0',
                    moving_stage_avg: '',
                    moving_stage_total: '0',
                    caligus_elongatus_avg: '',
                    caligus_elongatus_total: '0',
                    count: '',
                    living_weight: '',
                    depth_avg: '',
                },
            ];
        }) ?? [];

    const dataColumnNames = allData.length > 0 && allData[0] ? Object.keys(allData[0]) : [];

    const formattedDataColumnNames = dataColumnNames.map((e) => `day.${e}`);

    return { allData, formattedDataColumnNames, dataColumnNames };
};

export default LocationPreparePDFdata;
export { LocationPreparePDFdata };
