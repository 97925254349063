import React from 'react';
import { useTranslation } from 'react-i18next';
import { RiAlarmWarningLine } from 'react-icons/ri';
import formattedWeightUnit from 'utils/formattedWeightUnit';

import AnchorButton from '@/components/atoms/Buttons/AnchorButton';
import OptoTooltip from '@/components/atoms/OptoTooltip';
import SplitValueElement from '@/components/atoms/SplitValueElement';
import { createCageUrl } from '@/helpers/urlGenerator';

import styles from './LiceReportLocationTable.module.scss';

const LESS_COUNT = 50;

const norwegian2DecimalsFormatter = new Intl.NumberFormat('nb', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

const english2DecimalsFormatter = new Intl.NumberFormat('en', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

interface collumnSumFormattingProps {
    value: number | undefined;
    descimals?: number;
    locale?: string;
}
const collumnSumFormatting = ({
    value,
    descimals = 2,
    locale = 'en',
}: collumnSumFormattingProps): string => {
    if (value) {
        if (descimals == 2) {
            if (locale === 'nb' || locale === 'no-NB') {
                return norwegian2DecimalsFormatter.format(value);
            } else {
                return english2DecimalsFormatter.format(value);
            }
        }
        const textValue = value.toFixed(descimals);
        return textValue;
    }
    return '---';
};

export interface LocationCage {
    cageId: number;
    caligusElongatusAvg: number;
    caligusElongatusTotal: number;
    count: number;
    depthAvg: number;
    depthMax: number;
    depthMin: number;
    femaleAvg: number;
    femaleTotal: number;
    livingWeight: number;
    movingStageAvg: number;
    movingStageTotal: number;
    name: string;
    isIncluded?: boolean;
}

export interface LiceReportLocationTableProps {
    reportIndex: number;
    clientId: number;
    locationId: number;
    cages: LocationCage[];
    averages: {
        count: number;
        depthAvg: number;
        depthMax: number;
        depthMin: number;
        femaleAvg: number;
        caligusElongatusAvg: number;
        movingStageAvg: number;
        livingWeight: number;
        femaleTotal?: number;
        caligusElongatusTotal?: number;
        movingStageTotal?: number;
    };
    title: string;
    openDefault: boolean;
    onHandleCageSelected: (reportIndex: number, locationId: number, cage: LocationCage) => void;
    onHandleAllCagesSelected: (locationId: number, isSelected: boolean) => void;
}

const LiceReportLocationTable = ({
    reportIndex,
    clientId,
    locationId,
    cages,
    averages,
    title,
    onHandleCageSelected,
}: LiceReportLocationTableProps) => {
    const { t, i18n } = useTranslation();

    cages.sort((a, b) => parseFloat(a.name) - parseFloat(b.name) || a.name.localeCompare(b.name));

    const locale = i18n.language;

    // Reitholmen - week 15

    const CountWithAlarm = ({ cage }) => {
        const { count } = cage;

        let content = '';
        if (count === undefined) {
            content = t('No data found');
        } else if (count < LESS_COUNT) {
            content = t('Less than 50 counts');
        }
        return count < LESS_COUNT ? (
            <OptoTooltip nub="up-center" content={content}>
                {count === undefined ? (
                    ''
                ) : count > LESS_COUNT ? (
                    count
                ) : (
                    <span data-type="alarm">
                        <span>{count}</span>
                        <RiAlarmWarningLine />
                    </span>
                )}
            </OptoTooltip>
        ) : (
            <span>{count}</span>
        );
    };

    return (
        <div>
            <div className={styles.locationTitle}>{title} </div>
            <table className={styles.locationTable}>
                <thead>
                    <tr>
                        <th />
                        <th>
                            <span>
                                <span>{t('Name')}</span>
                            </span>
                        </th>
                        <th>
                            <span>
                                <span>{t('Adult Female')}</span>
                                <SplitValueElement>
                                    <span>{t('Average')}</span>
                                    <span>{t('Total')}</span>
                                </SplitValueElement>
                            </span>
                        </th>
                        <th>
                            <span>
                                <span>{t('Mobile Lice')}</span>
                                <SplitValueElement>
                                    <span>{t('Average')}</span>
                                    <span>{t('Total')}</span>
                                </SplitValueElement>
                            </span>
                        </th>
                        <th>
                            <span>
                                <span>{t('Caligus elongatus')}</span>
                                <SplitValueElement>
                                    <span>{t('Average')}</span>
                                    <span>{t('Total')}</span>
                                </SplitValueElement>
                            </span>
                        </th>
                        <th>
                            <span>
                                <span>{t('Count')}</span>
                            </span>
                        </th>
                        <th>
                            <span>
                                <span>{t('Weight (g)')}</span>
                            </span>
                        </th>
                        <th>
                            <span>
                                <span>{t('Depth (m)')}</span>
                            </span>
                        </th>
                        <th>
                            <span>
                                <span>{t('Include')}</span>
                            </span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {cages.map((cage) => {
                        return (
                            <tr key={cage.cageId}>
                                <td
                                    title={`${t('Go to')} ${t('Cage')} ${cage.name} ${t(
                                        'lice-count'
                                    )}`}
                                    data-label={t('Cage')}>
                                    <AnchorButton
                                        to={`${createCageUrl(
                                            clientId,
                                            locationId,
                                            cage.cageId
                                        )}/lice-count`}
                                        variant="table"
                                        tooltip={`${t('Go to')} ${t('Cage')} ${cage.name} ${t(
                                            'Lice'
                                        )}`}
                                        size="small">
                                        <span>{t('Open')}</span>
                                    </AnchorButton>
                                </td>
                                <td data-label={t('Cage')}>
                                    <span>{cage.name}</span>
                                </td>
                                <td
                                    data-label={t('Adult Female')}
                                    data-sub-label={`(${t('Avg.')} | ${t('Total')})`}>
                                    <SplitValueElement>
                                        <span>
                                            {collumnSumFormatting({
                                                value: cage.femaleAvg,
                                                locale: locale,
                                            })}
                                        </span>
                                        <span>
                                            {collumnSumFormatting({
                                                value: cage.femaleTotal,
                                                descimals: 0,
                                                locale: locale,
                                            })}
                                        </span>
                                    </SplitValueElement>
                                </td>
                                <td
                                    data-label={t('Mobile')}
                                    data-sub-label={`(${t('Avg.')} | ${t('Total')})`}>
                                    <SplitValueElement>
                                        <span>
                                            {collumnSumFormatting({
                                                value: cage.movingStageAvg,
                                                locale: locale,
                                            })}
                                        </span>
                                        <span>
                                            {collumnSumFormatting({
                                                value: cage.movingStageTotal,
                                                descimals: 0,
                                                locale: locale,
                                            })}
                                        </span>
                                    </SplitValueElement>
                                </td>
                                <td
                                    data-label={t('Caligus')}
                                    data-sub-label={`(${t('Avg.')} | ${t('Total')})`}>
                                    <SplitValueElement>
                                        <span>
                                            {collumnSumFormatting({
                                                value: cage.caligusElongatusAvg,
                                                locale: locale,
                                            })}
                                        </span>
                                        <span>
                                            {collumnSumFormatting({
                                                value: cage.caligusElongatusTotal,
                                                descimals: 0,
                                            })}
                                        </span>
                                    </SplitValueElement>
                                </td>
                                <td data-label={t('Count')}>
                                    <CountWithAlarm cage={cage} />
                                </td>
                                <td data-label={t('Weight')}>
                                    <span>
                                        {collumnSumFormatting({
                                            value: formattedWeightUnit(
                                                cage.livingWeight,
                                                'g',
                                                0,
                                                false
                                            ).value,
                                            descimals: 0,
                                        })}
                                    </span>
                                </td>
                                <td data-label={t('Depth')}>
                                    <span>
                                        {collumnSumFormatting({
                                            value: cage.depthAvg,
                                            descimals: 1,
                                            locale: locale,
                                        })}
                                    </span>
                                </td>
                                <td>
                                    <input
                                        type="checkbox"
                                        checked={cage.isIncluded}
                                        onChange={() =>
                                            onHandleCageSelected(reportIndex, locationId, cage)
                                        }
                                    />
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
                <tfoot>
                    <tr>
                        <td />
                        <td />
                        <td data-label={t('Average Adult Female')}>
                            <SplitValueElement variant="highlight">
                                <OptoTooltip content={t('Average Adult Female')} nub={'up-center'}>
                                    <em>
                                        {collumnSumFormatting({
                                            value: averages.femaleAvg,
                                            locale: locale,
                                        })}
                                    </em>
                                </OptoTooltip>
                                <OptoTooltip content={t('Total adult female')} nub="up-center">
                                    <em>
                                        {collumnSumFormatting({
                                            value: averages.femaleTotal,
                                            descimals: 0,
                                        })}
                                    </em>
                                </OptoTooltip>
                            </SplitValueElement>
                        </td>
                        <td data-label={t('Average Mobile')}>
                            <SplitValueElement variant="highlight">
                                <OptoTooltip content={t('Average Mobile')} nub="up-center">
                                    <em>
                                        {collumnSumFormatting({
                                            value: averages.movingStageAvg,
                                            locale: locale,
                                        })}
                                    </em>
                                </OptoTooltip>
                                <OptoTooltip content={t('Total mobile')} nub="up-center">
                                    <em>
                                        {collumnSumFormatting({
                                            value: averages.movingStageTotal,
                                            descimals: 0,
                                            locale: locale,
                                        })}
                                    </em>
                                </OptoTooltip>
                            </SplitValueElement>
                        </td>
                        <td data-label={t('Average Caligus')}>
                            <SplitValueElement variant="highlight">
                                <OptoTooltip content={t('Average Caligus')} nub="up-center">
                                    <em>
                                        {collumnSumFormatting({
                                            value: averages.caligusElongatusAvg,
                                            locale: locale,
                                        })}
                                    </em>
                                </OptoTooltip>
                                <OptoTooltip content={t('Total caligus')} nub="up-center">
                                    <em>
                                        {collumnSumFormatting({
                                            value: averages.caligusElongatusTotal,
                                            descimals: 0,
                                        })}
                                    </em>
                                </OptoTooltip>
                            </SplitValueElement>
                        </td>
                        <td data-label={t('Count')}>
                            <OptoTooltip content={t('Total count')} nub="up-center">
                                <em>
                                    {collumnSumFormatting({
                                        value: averages.count,
                                        descimals: 0,
                                    })}
                                </em>
                            </OptoTooltip>
                        </td>
                        <td data-label={t('Weight')}>
                            <OptoTooltip content={t('Average weight')} nub="up-center">
                                <em>
                                    {collumnSumFormatting({
                                        value: formattedWeightUnit(
                                            averages.livingWeight,
                                            'g',
                                            0,
                                            false
                                        ).value,
                                        descimals: 0,
                                    })}
                                </em>
                            </OptoTooltip>
                        </td>
                        <td data-label={t('Depth')}>
                            <OptoTooltip content={t('Average Depth')} nub="up-center">
                                <em>
                                    {collumnSumFormatting({
                                        value: averages.depthAvg,
                                        descimals: 1,
                                        locale: locale,
                                    })}
                                </em>
                            </OptoTooltip>
                        </td>
                        <td />
                    </tr>
                </tfoot>
            </table>
        </div>
    );
};

export default LiceReportLocationTable;
export { LiceReportLocationTable };
