import { useTranslation } from 'react-i18next';
import dateUtils from 'helpers/date';
import { Version } from 'services/types';

import Loader from 'components/atoms/Loader';

import styles from './BioscopeMeta.module.scss';

interface BioscopeMetaProps {
    title?: string;
    isLoading: boolean;
    bioscopeMetaData: Version;
}

const BioscopeMeta = ({ title = 'Meta', isLoading, bioscopeMetaData }: BioscopeMetaProps) => {
    const { t } = useTranslation();
    let recordingVersion: string;
    let processingVersion: string;
    if (bioscopeMetaData?.version) {
        const matches = bioscopeMetaData.version.match(/v?\d.*/g);
        if (matches) {
            [recordingVersion, processingVersion] = matches;
        }
    }

    return (
        <div className={styles.bioscopeMeta}>
            <h2>{t(title)}</h2>
            {isLoading ? (
                <Loader />
            ) : (
                <div className={'mt-3'}>
                    <table className={styles.bioscopeDetailsTable}>
                        <tbody>
                            <tr>
                                <th>{t('Version')}</th>
                                <td>
                                    (
                                    {bioscopeMetaData?.version
                                        ? dateUtils.niceDateTime(bioscopeMetaData.updatedAt)
                                        : t('Never updated')}
                                    )
                                </td>
                            </tr>
                            {bioscopeMetaData?.version && (
                                <>
                                    <tr>
                                        <td>Recording</td>
                                        <td>
                                            <span className={'badge badge-secondary'}>
                                                {recordingVersion}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Processing</td>
                                        <td>
                                            <span className="badge badge-secondary">
                                                {processingVersion}
                                            </span>
                                        </td>
                                    </tr>
                                </>
                            )}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default BioscopeMeta;
export { BioscopeMeta };
