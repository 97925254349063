import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import './i18n';

import SentryErrorBoundary from './components/SentryErrorBoundary';
import AuthProvider from './containers/AuthProvider';
import HeaderProvider from './containers/HeaderProvider';
import MqttProvider from './contexts/mqtt-provider-context';
import Routes from './routes';

import 'react-toastify/dist/ReactToastify.css';
import './components/molecules/ToastTemplate/ToastTemplate_cssvariables.scss';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            staleTime: 1000 * 60 * 5,
            cacheTime: 1000 * 60 * 10,
        },
    },
});

// contextSharing={true}

const Root = () => {
    return (
        <SentryErrorBoundary>
            <BrowserRouter>
                <QueryClientProvider client={queryClient}>
                    <AuthProvider>
                        <MqttProvider>
                            <HeaderProvider>
                                <ToastContainer containerId="optoscale-toast" draggable={false} />
                                <Routes />
                            </HeaderProvider>
                        </MqttProvider>
                    </AuthProvider>
                </QueryClientProvider>
            </BrowserRouter>
        </SentryErrorBoundary>
    );
};

export default Root;
