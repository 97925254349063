import React, { HTMLProps, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './FormInput.module.scss';

export interface FormInputProps extends HTMLProps<HTMLInputElement> {
    name: string;
    label: string;
    value: string | number;
    required?: boolean;
    minLength?: number;
    maxLength?: number;
    size?: number;
    validationRegex?: string;
    validationMessage?: string;
    readonly?: boolean;
    disabled?: boolean;
    id: string | undefined;
    type?: 'text' | 'number';
    step?: number;
}

const FormInputElement = ({
    label,
    name,
    maxLength = 255,
    minLength = 0,
    required = false,
    size = 20,
    id,
    validationMessage = '',
    validationRegex = '.*',
    readonly = false,
    disabled = false,
    value = 0,
    type = 'number',
    step = 1,
    onChange,
}: FormInputProps) => {
    const [internalValue, setValue] = useState<string | number>(value);
    const { t } = useTranslation();

    return (
        <div className={styles.forminput}>
            <span>
                <label htmlFor={id}>{t(label)}</label>
                {type === 'number' ? (
                    <input
                        type={type}
                        step={step}
                        id={id}
                        value={internalValue as string}
                        name={name}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setValue(e.target.value);
                            if (onChange) {
                                onChange(e);
                            }
                        }}
                        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                            const isValid = e.target.checkValidity();
                            //e.target.reportValidity();

                            if (e.target.type === 'number' && !e.target.value) {
                                e.target.setCustomValidity('This field is required');
                            } else {
                                e.target.setCustomValidity('');
                            }

                            const event = new CustomEvent('disableSubmit', {
                                bubbles: true,
                                composed: true,
                                detail: {
                                    message:
                                        validationMessage.length > 0
                                            ? validationMessage
                                            : `${name} must be between ${minLength} and ${maxLength} and match the pattern ${validationRegex}`,
                                    isValid: isValid,
                                    id: id,
                                },
                            });

                            !isValid && e.target.focus();

                            e.target.dispatchEvent(event);
                        }}
                        min={minLength}
                        max={maxLength}
                        size={size}
                        pattern={validationRegex}
                        required={required}
                        data-validation-message={validationMessage}
                        readOnly={readonly}
                        disabled={disabled}
                    />
                ) : (
                    <input
                        type={type}
                        id={id}
                        value={internalValue as string}
                        name={name}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setValue(e.target.value);
                            if (onChange) {
                                onChange(e);
                            }
                        }}
                        minLength={minLength}
                        maxLength={maxLength}
                        size={size}
                        pattern={validationRegex}
                        required={required}
                        data-validation-message={validationMessage}
                        readOnly={readonly}
                        disabled={disabled}
                    />
                )}
                <span data-type="validity">
                    {validationMessage && validationMessage.length > 0
                        ? validationMessage
                        : `${name} must be between ${minLength} and ${maxLength} and match the pattern ${validationRegex}`}
                </span>
            </span>
        </div>
    );
};

export default FormInputElement;
export { FormInputElement };
