import React from 'react';

import styles from './PageTitleRow.module.scss';

interface PageTitleRowProps {
    children?: React.ReactNode;
    title?: string | undefined;
    subTitle?: string | undefined | null;
    importantMessage?: React.ReactNode;
}

const PageTitleRow = ({ children, title, subTitle, importantMessage }: PageTitleRowProps) => {
    return (
        <div className={styles.headerContainer}>
            <div className={styles.leftTextHeaderContainer}>
                <h1 className={styles.titleElement}>{title ? title : ''}</h1>
                {subTitle && <h3 className={styles.subTitleElement}>{subTitle}</h3>}
            </div>

            {importantMessage && <div>{importantMessage}</div>}
            {children && <div className={styles.headerBlock}>{children}</div>}
        </div>
    );
};

export default PageTitleRow;
export { PageTitleRow };
