import React from 'react';
import { Alert } from 'reactstrap';
import classNames from 'classnames';
import { t } from 'i18next';
import styled from 'styled-components';

import styles from './loader.module.scss';
import salmonLoaderImage from 'assets/salmon-loader-4.svg';
import salmonLoaderSantaImage from 'assets/salmon-loader-4-santa.svg';
const LoaderImage = styled.img`
    margin: auto;
    display: block;
    width: ${(props) => props.size};
    height: ${(props) => props.size};
`;

const SalmonLoader = ({ size = '100px' }: LoaderProps) => (
    <LoaderImage size={size} src={salmonLoaderImage} className={styles.fadeIn} alt="Loading" />
);
const SalmonLoaderSanta = ({ size = '180px' }: LoaderProps) => (
    <LoaderImage
        size={size}
        className={classNames(styles.santaLoader)}
        src={salmonLoaderSantaImage}
        alt="Loading"
    />
);

interface LoaderProps {
    style?: 'salmon' | 'salmonSanta' | 'old';
    error?: any;
    loading?: boolean;
    size?: string | number;
    children?: React.ReactNode;
}
const Loader = (props: LoaderProps) => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth(); // Get the current month (0-11)
    const isDecember = currentMonth === 11;
    const ActualLoader = () => {
        switch (props.style) {
            case 'salmon':
                return <SalmonLoader {...props} />;

            case 'salmonSanta':
                return <SalmonLoaderSanta {...props} />;

            default:
                if (isDecember) {
                    return <SalmonLoaderSanta {...props} />;
                }
                return <SalmonLoader {...props} />;
        }
    };
    if (props.error) {
        return <Alert color="danger">{t('Something happened')}</Alert>;
    }
    if (props.loading || props.loading === undefined) {
        return <ActualLoader />;
    }
    return <>{props.children}</>;
};

export default Loader;
export { Loader };
