import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import isEqual from 'lodash/isEqual';
import { AllWeeklyReportResult, LiceReportCages } from 'services/types';

import UmerBox from '@/components/atoms/UmerBox';
import { UmerBoxElementHeader } from '@/components/atoms/UmerBoxElements/UmerBoxElementHeader';
import DownloadCsvXlsx from '@/components/molecules/DownloadCsvXlsx';
import { DownloadPDF } from '@/components/molecules/DownloadPDF/DownloadPDFButton';
import { PageHeaderBar } from '@/components/molecules/PageHeaderBar';
import { PageTitleRow } from '@/components/molecules/PageTitleRow';
import { LiceReportConfigurationPanel } from '@/components/organisms/LiceReportConfigurationPanel';
import LiceReportLocationTable, {
    LiceReportLocationTableProps,
    LocationCage,
} from '@/components/organisms/LiceReportLocationTable/LiceReportLocationTable';
import calculateAverage, { CalulateAverage } from '@/utils/calculateCageAverages';

import { PDFData } from './PdfReport/RegionLicePDFContent';
import RegionPreparePDFdata from './PdfReport/RegionPrepareDataForPDFGeneration';

import styles from './RegionLiceReport.module.scss';

export interface LocationsIncluded {
    id: number;
    name: string;
    cages: LiceReportCages[];
}

interface RegionLiceReportPageProps {
    clientId: number;
    clientName: string;
    reports: AllWeeklyReportResult[];
}

type AllweeklyReportResultWithTotals = AllWeeklyReportResult & {
    locations: (LocationsIncluded & {
        cages: LiceReportCages[];
    })[];
};

type ReportConfiguration = {
    from?: string;
    to?: string;
    weekNumbers?: { weekNumber: number; year: number; interimReport: boolean; key: string }[];
    locations?: LocationsIncluded[];
    dataSoureType?: 'simulated' | 'raw';
};

const RegionLiceReportPageContent = ({
    reports,
    clientId,
    clientName,
}: RegionLiceReportPageProps) => {
    const { t, i18n } = useTranslation();

    const [weekStringToReportMap, setWeekStringToReportMap] = useState<
        Map<string, AllweeklyReportResultWithTotals>
    >(new Map<string, AllweeklyReportResultWithTotals>());
    const [weekStringOrder, setWeekStringOrder] = useState<string[]>([]);

    const [selectedReports, setSelectedReports] = useState<AllweeklyReportResultWithTotals[]>([]);

    const updateReportMap = () => {
        const map = new Map<string, AllweeklyReportResultWithTotals>();
        // biome-ignore lint/complexity/noForEach: <explanation>
        const weekStringKeysInOrder: string[] = [];
        reports.forEach((report) => {
            const key = `${report.isoWeek}-${report.isoYear}`;
            weekStringKeysInOrder.push(key);
            map.set(key, {
                ...report,
                locations: report.locations.map((location) => {
                    return {
                        ...location,
                        cages: location.cages.map((cage) => {
                            return {
                                ...cage,
                            };
                        }),
                    };
                }),
            });
        });
        setWeekStringOrder(weekStringKeysInOrder);
        setWeekStringToReportMap(map);

        // set up defaults here to support null handling (instead of injecting in the state setter)
        if (selectedReports.length === 0 && weekStringKeysInOrder.length > 0) {
            const initalSelectedReports = [
                map.get(weekStringKeysInOrder[0]) as AllweeklyReportResultWithTotals,
            ];
            setSelectedReports(initalSelectedReports);
        }
    };

    const [reportConfiguration, setReportConfiguration] = useState<ReportConfiguration>({
        dataSoureType: 'raw',
        ...(reports[0]
            ? [
                  {
                      weekNumber: reports[0].isoWeek,
                      year: reports[0].isoYear,
                      interimReport: reports[0].interimReport,
                      key: `${reports[0].isoWeek}-${reports[0].isoYear}`,
                  },
              ]
            : []),
    });

    useEffect(() => {
        updateReportMap();
    }, []);

    useEffect(() => {
        updateReportMap();
    }, [reports]);

    useEffect(() => {
        const newSelectedReports =
            reportConfiguration.weekNumbers !== undefined
                ? reportConfiguration.weekNumbers?.map(({ key }) => {
                      return weekStringToReportMap?.get(key);
                  })
                : ([] as AllweeklyReportResultWithTotals[]);

        if (newSelectedReports !== undefined && newSelectedReports.length > 0) {
            setSelectedReports(
                newSelectedReports.filter(
                    (report): report is AllweeklyReportResultWithTotals => report !== undefined
                )
            );
            return;
        }
    }, [reportConfiguration, weekStringToReportMap]);

    const handleCageSelected = (reportIndex: number, locationId: number, cage: LocationCage) => {
        const newSelectedReports = selectedReports.map((report, currentReportIndex) => {
            const newLocations = report.locations.map((location) => {
                if (location.id === locationId && reportIndex === currentReportIndex) {
                    return {
                        ...location,
                        cages: location.cages.map((c) => {
                            if (c.cageId === cage.cageId) {
                                return {
                                    ...c,
                                    isIncluded: !c.isIncluded,
                                };
                            }
                            return c;
                        }),
                    };
                }

                return location;
            });
            return {
                ...report,
                locations: newLocations,
            };
        });

        setSelectedReports(
            newSelectedReports.filter(
                (report): report is AllweeklyReportResultWithTotals => report !== undefined
            )
        );
    };

    const handleAllCagesSelected = (locationId: number, isSelected: boolean) => {
        // Not implemented yet. Can not see this feature shown on existing UI
    };

    const prepareTitle = (report: AllWeeklyReportResult) => {
        return `${t('Week')} ${report.isoWeek} ${report.isoYear}`;
    };

    const prepareWeekNumberObjects = (reports: AllWeeklyReportResult[]) => {
        return reports.map((report) => ({
            weekNumber: report.isoWeek,
            year: report.isoYear,
            interimReport: report.interimReport,
            key: `${report.isoWeek}-${report.isoYear}`,
        }));
    };

    const getLiceReportPrefix = () => {
        if (clientName) {
            return `${clientName}-lice-report`;
        }
        return `${clientId}-lice-report`;
    };

    const onConfigurationChangeHandler = (config: ReportConfiguration) => {
        if (config?.weekNumbers.length === 0) {
            config = {
                ...config,
                weekNumbers: [
                    {
                        weekNumber: reports[0].isoWeek,
                        year: reports[0].isoYear,
                        interimReport: reports[0].interimReport,
                        key: `${reports[0].isoWeek}-${reports[0].isoYear}`,
                    },
                ],
            };
        }

        setReportConfiguration((prevConfig) => {
            if (isEqual(prevConfig, config)) {
                return prevConfig;
            }
            return { ...prevConfig, ...config };
        });
    };

    return (
        <div className={styles.liceReportPage}>
            <PageTitleRow title={t('Lice report')}>
                {weekStringOrder.length > 0 && (
                    <LiceReportConfigurationPanel
                        weekNumberObjects={prepareWeekNumberObjects(reports)}
                        onReportConfigurationChangeHandler={onConfigurationChangeHandler}
                    />
                )}
            </PageTitleRow>

            {selectedReports
                ?.filter((report) => report !== undefined)
                .map((report, reportIndex) => {
                    const { isoWeek, isoYear, locations } = report;
                    const { allData: pdfAllData } = RegionPreparePDFdata({
                        locationsIncluded: locations as LocationsIncluded[],
                        clientName,
                        weekNumber: isoWeek.toString(),
                        locale: i18n.language,
                    });
                    const { allData: csvAllData, formattedDataColumnNames } = RegionPreparePDFdata({
                        locationsIncluded: locations as LocationsIncluded[],
                        clientName,
                        weekNumber: isoWeek.toString(),
                        locale: 'en',
                    });
                    return (
                        <UmerBox key={isoWeek.toString()}>
                            <PageHeaderBar>
                                <UmerBoxElementHeader
                                    level={'h3'}
                                    headerText={prepareTitle(report)}
                                    rightSideContent={
                                        <div className={styles.actionButtonContainer}>
                                            <DownloadCsvXlsx
                                                label="CSV"
                                                data={csvAllData}
                                                nameToInclude={`${getLiceReportPrefix()}-${t(
                                                    'Week'
                                                )}-${isoWeek.toString()}`}
                                                COLUMNS={formattedDataColumnNames}
                                            />
                                            <DownloadCsvXlsx
                                                label="XLSX"
                                                data={pdfAllData}
                                                nameToInclude={`${getLiceReportPrefix()}-${t(
                                                    'Week'
                                                )}-${isoWeek.toString()}`}
                                                COLUMNS={formattedDataColumnNames}
                                            />

                                            <DownloadPDF
                                                buttonText={'PDF'}
                                                docGenerator={async () => {
                                                    const { RegionLicePDFContent } = await import(
                                                        './PdfReport/RegionLicePDFContent'
                                                    );
                                                    return (
                                                        <RegionLicePDFContent
                                                            clientName={clientName}
                                                            allData={pdfAllData as PDFData[]}
                                                            weekNumber={isoWeek.toString()}
                                                        />
                                                    );
                                                }}
                                                fileName={`${getLiceReportPrefix()}-${t(
                                                    'Week'
                                                )}-${isoWeek.toString()}`}
                                            />
                                        </div>
                                    }
                                />
                            </PageHeaderBar>
                            <div className={styles.locationReportsContainer}>
                                {report.locations?.map((location) => (
                                    <LiceReportLocationTable
                                        reportIndex={reportIndex}
                                        clientId={clientId}
                                        locationId={location.id}
                                        key={location.id}
                                        cages={
                                            location.cages as LiceReportLocationTableProps['cages']
                                        }
                                        title={location.name}
                                        openDefault={true}
                                        onHandleCageSelected={handleCageSelected}
                                        onHandleAllCagesSelected={handleAllCagesSelected}
                                        averages={calculateAverage({
                                            cages: location.cages,
                                        } as unknown as CalulateAverage)}
                                    />
                                ))}
                            </div>
                        </UmerBox>
                    );
                })}
        </div>
    );
};

export default RegionLiceReportPageContent;
export { RegionLiceReportPageContent };
