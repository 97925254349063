import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { Button } from '@/components/atoms/Buttons';
import { PillSwitchWrapper } from '@/components/atoms/PillSwitch/PillSwitch';

import styles from './LiceReportConfigurationPanel.module.scss';

type WeekNumberObject = {
    weekNumber: number;
    year: number;
    interimReport: boolean;
    key: string;
};

type ReportConfigurationObject = {
    from?: string;
    to?: string;
    weekNumbers?: WeekNumberObject[];
    dataSoureType?: 'simulated' | 'raw';
};

export interface LiceReportConfigurationPanelProps {
    children?: React.ReactNode;
    weekNumberObjects: WeekNumberObject[];
    onReportConfigurationChangeHandler?: (config: ReportConfigurationObject) => void;
    dataSoureType?: 'raw' | 'simulated';
    showDatePicker?: boolean;
    hideShowbutton?: boolean;
    hideTitleRow?: boolean;
}

const LiceReportConfigurationPanel = ({
    children,
    weekNumberObjects = [],
    onReportConfigurationChangeHandler = () => {},
    dataSoureType = 'raw',
    showDatePicker = false,
    hideShowbutton = false,
    hideTitleRow = true,
}: LiceReportConfigurationPanelProps) => {
    const { t } = useTranslation();

    const [showingMore, setShowingMore] = useState<boolean>(true);
    const [showMoreButton, setShowMoreButton] = useState(false);
    const [reportDateRange, setReportDateRange] = useState<{ from: string; to: string }>({
        from: moment().subtract(1, 'week').format('YYYY-MM-DD'),
        to: moment().format('YYYY-MM-DD'),
    });

    const numberOfPillsForShowMore = 10;

    const [weekNumbers, setWeeksNumbers] = useState<WeekNumberObject[]>([]);
    const [currentWeekNumberObjects, setCurrentWeekNumberObjects] =
        useState<WeekNumberObject[]>(weekNumberObjects);

    useEffect(() => {
        if (weekNumberObjects.length > numberOfPillsForShowMore) {
            setCurrentWeekNumberObjects(weekNumberObjects.slice(0, numberOfPillsForShowMore));
            setShowMoreButton(true);
            setShowingMore(false);
        } else {
            setCurrentWeekNumberObjects(weekNumberObjects);
        }
    }, []);

    const toggleShowMoreHandler = () => {
        if (showingMore) {
            setCurrentWeekNumberObjects(weekNumberObjects.slice(0, numberOfPillsForShowMore));
        } else {
            setCurrentWeekNumberObjects(weekNumberObjects);
        }
        setShowingMore(!showingMore);
    };

    useEffect(() => {
        onReportConfigurationChangeHandler({
            ...reportDateRange,
            weekNumbers: [...weekNumbers],
        });
    }, [reportDateRange, weekNumbers]);

    return (
        <div className={styles.licereportconfigurationpanel} data-open={showingMore}>
            <div className={styles.weekSelectorContainer}>
                {!hideTitleRow && <h3>Weekly reports</h3>}
                <PillSwitchWrapper
                    onPillListChange={(newWeeks) => {
                        setWeeksNumbers([...newWeeks]);
                    }}
                    pillDataObjects={currentWeekNumberObjects}
                />
                {showMoreButton && (
                    <div data-type="show_more">
                        <Button
                            variant="secondary"
                            name="show_more"
                            size="small"
                            onClick={toggleShowMoreHandler}>
                            {showingMore ? t('Show less') : t('Show more')}
                        </Button>
                    </div>
                )}
            </div>
            {children}
        </div>
    );
};

export default LiceReportConfigurationPanel;
export { LiceReportConfigurationPanel };
