import type React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useResizeDetector } from 'react-resize-detector';
import { LEFT, RIGHT, useSwipeable } from 'react-swipeable';
import { mergeDeep } from 'immutable';

import IconButton from '@/components/atoms/Buttons/IconButton';
import { CheckBoxSwitch } from '@/components/atoms/CheckBoxSwitch';
import { GenericIcon } from '@/components/atoms/icons/GenericIcon';
import * as iconUrls from '@/components/atoms/icons/GenericIcon/GenericIcons';
import { OptoTooltip } from '@/components/atoms/OptoTooltip';
import DropDownPanel from '@/components/molecules/DropDownPanel';
import { ShareFalseDetectionDropdown } from '@/components/molecules/ShareFalseDetectionDropdown';
import { ShareImage, ShareImageTriggerButton } from '@/components/molecules/ShareImage';
import { ImageFeedbackDto, ImagesIndicatorResult } from '@/services/types';
import { DownloadImages } from 'components/atoms/DownloadImages/DownloadImages';
import { FishHealthNavigationCard } from 'components/atoms/FishHealthMetaCard';
import { ScrollNavigator } from 'components/atoms/ScrollNavigator';

import { ImageControl } from './ImageControl';
import { mapImpagesToNavigationCardInfoObjects } from './ImageMapper';
import { ImageMetaInfo } from './ImageMetaInfo';
import { ImageViewer, type ImageViewerProps } from './ImageViewer';
import { ImageViewerActionMenu } from './ImageViewerActionMenu';
import { ViewerHelpTextBlocks } from './ViewerHelpTextBlocks';

import styles from './ImageViewer.module.scss';

export interface Image {
    id: string;
    timeStamp: string;
    day: string;
    path: string;
    width: number;
    height: number;
    permalink?: string;
    scpCommand?: string;
    bboxes?: {
        tag: string;
        bbox: number[];
        size_x_mm?: number;
        size_y_mm?: number;
        score: number;
    }[];
    indicators?: ImagesIndicatorResult;
    score?: 'laksvel_0' | 'laksvel_1' | 'laksvel_2' | 'laksvel_3';
}

export interface viewerConfigProps {
    showPois?: boolean; // enable or disable POIs (Point of interessed)
    showWoundLabel?: boolean; // is healing and active labels shown in navigator
    showLiceLabel?: boolean; // is lice labels shown in navigator
    showLaksvelLabel?: boolean; // is laksvel labels shown in navigator
    zoomDisabled?: boolean; // turn on/off zooming
    showMagnifyer?: boolean; // turn on/off magnifyer by default on load.
    scrollActivated?: boolean; // turn on/off scroll navigator
    magnifyerDisabled?: boolean; // disable/enable magnifyer feature
    showHelpText?: boolean; //disable/enable showing the help text
}

export interface ImageViewContainerProps {
    key?: string;
    instanceKey: string;
    isColor?: boolean;
    images: Image[];
    initialImageIndex?: number;
    dayswithimages?: { day: string; count: number }[];
    onAfterTimelineChangeHandler?: (value: string) => void;
    onCommitImageFeedbackDto?: (imageFeedbackDto: ImageFeedbackDto) => void;
    viewerConfig?: viewerConfigProps;
    isOptoScaleAdmin: boolean;
}

const defaultConfig: viewerConfigProps = {
    showPois: true,
    showWoundLabel: true,
    zoomDisabled: true,
    showLiceLabel: false,
    showLaksvelLabel: false,
    showMagnifyer: false,
    scrollActivated: true,
    magnifyerDisabled: false,
    showHelpText: true,
};

const ImageViewContainer = ({
    instanceKey,
    isColor = true,
    images = [],
    initialImageIndex = 0,
    dayswithimages = [],
    onAfterTimelineChangeHandler = () => {},
    onCommitImageFeedbackDto = () => {},
    viewerConfig = defaultConfig,
    isOptoScaleAdmin,
}: ImageViewContainerProps) => {
    const config = mergeDeep(defaultConfig, { ...viewerConfig });
    const { t } = useTranslation();

    // Dom references
    const viewerRef = useRef<HTMLDivElement>(null);
    const viewerChildRef = useRef(null);
    const overlayRef = useRef(null);
    const toolTipref = useRef(null);
    const scrollNavigatorRef = useRef(null);
    const wrapperref = useRef(null);
    const magnifyerLayerRef = useRef(null);

    const [isFullScreen, setIsFullscreen] = useState<boolean>(false);
    const [showPoiMeta, setShowPoiMeta] = useState<boolean>(config.showPois ?? false);
    const [imageIndex, setImageIndex] = useState(initialImageIndex);
    const [useMagnifyer, setUseMagnifyer] = useState<boolean>(config?.showMagnifyer ?? false);
    const [useHelp, setUseHelp] = useState<boolean>(false);

    const [prevImages, setPrevImages] = useState(images);
    if (images !== prevImages) {
        setImageIndex(0);
        setPrevImages(images);
    }

    // const [showLiceFeedbackOptions, setShowLiceFeedbackOptions] = useState(true);
    // const [showWoundFeedbackOptions, setShowWoundFeedbackOptions] = useState(false);
    //
    // if (instanceKey !== 'licedetections') {
    //     setShowWoundFeedbackOptions(true);
    // }
    //

    useResizeDetector({
        handleWidth: true,
        targetRef: wrapperref,
        onResize: (width, height) => {
            if (wrapperref.current !== null) {
                wrapperref.current.style.setProperty('--image-height', `${height}px`);
                wrapperref.current.style.setProperty('--image-width', `${width}px`);
                wrapperref.current.dataset.width = `${width}`;
                wrapperref.current.dataset.height = `${height}`;

                if (config.showMagnifyer) {
                    if (wrapperref.current.dataset?.origwidth <= width) {
                        setUseMagnifyer(false);
                    } else {
                        setUseMagnifyer(true);
                    }
                }
            }
        },
    });

    const swipeLefAndRightHandler = (eventData: any) => {
        const updatedIndex =
            eventData.dir === LEFT
                ? (imageIndex + 1) % images.length
                : (imageIndex - 1 + images.length) % images.length;

        setImageIndex(updatedIndex);
        viewerRef.current.style.setProperty('--imageOpacity', '1');
        viewerChildRef.current.resetZoom();

        toolTipref.current.style.display = 'none';
    };

    const handlers = useSwipeable({
        onTap: (eventData) => {
            // const { clientX, clientY } = eventData.event as MouseEvent;
            // setTapClickCoordinate({ x: clientX, y: clientY });
        },
        onSwiping: (eventData) => {
            // Fade to black on swipe
            if (eventData.dir === LEFT || eventData.dir === RIGHT) {
                viewerRef.current.style.setProperty('--imageOpacity', '0');
            }

            setTimeout(() => {
                viewerRef.current.style.setProperty('--imageOpacity', '1');
            }, 1000);
        },
        onSwipedLeft: swipeLefAndRightHandler,
        onSwipedRight: swipeLefAndRightHandler,

        delta: 10, // min distance(px) before a swipe starts. *See Notes*
        preventScrollOnSwipe: false, // prevents scroll during swipe (*See Details*)
        trackTouch: true, // track touch input
        trackMouse: true, // track mouse input
        rotationAngle: 0, // set a rotation angle
        swipeDuration: Number.POSITIVE_INFINITY, // allowable duration of a swipe (ms). *See Notes*
        touchEventOptions: { passive: true }, // options for touch listeners (*See Details*)
    });

    const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        viewerChildRef.current?.resetZoom();
        const direction = event.currentTarget.value;
        const updatedIndex =
            direction.toLowerCase() === 'next'
                ? (imageIndex + 1) % images.length
                : imageIndex === 0
                  ? images.length - 1
                  : imageIndex - 1;
        setImageIndex(updatedIndex);
    };

    const handleFishNavigation = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        viewerChildRef.current?.resetZoom();
        const selectedIndex = images.findIndex(
            (image) => `${image.id}` === `${event.currentTarget.value}`
        );
        setImageIndex(selectedIndex);
    };

    const poiPositionHandler = (poi, rect) => {
        if (magnifyerLayerRef.current) {
            magnifyerLayerRef.current.style.setProperty('--mlayer-width', `${rect.width}px`);
            magnifyerLayerRef.current.style.setProperty('--mlayer-height', `${rect.height}px`);
        }

        if (viewerRef.current) {
            viewerRef.current.style.setProperty('--mlayer-width', `${rect.width}px`);
            viewerRef.current.style.setProperty('--mlayer-height', `${rect.height}px`);
        }
    };

    const currentVisibleImage = images.length > 0 && images[imageIndex] ? images[imageIndex] : null;
    let imageViewObject: ImageViewerProps = null;
    let navigationCardInfoObjects = [];

    if (currentVisibleImage) {
        const { height, id, path, timeStamp, width, bboxes } = currentVisibleImage;
        imageViewObject = {
            isColor: isColor,
            id,
            path,
            timeStamp,
            dimensions: {
                width,
                height,
            },
            bboxes,
            poiPositionHandler: poiPositionHandler,
            showPoiMeta: showPoiMeta,
        };
        if (wrapperref.current !== null) {
            wrapperref.current.dataset.origwidth = `${width}`;
            wrapperref.current.dataset.origheight = `${height}`;
        }

        navigationCardInfoObjects = mapImpagesToNavigationCardInfoObjects(images);
    }
    useEffect(() => {
        if (scrollNavigatorRef.current) {
            const { width, height } = wrapperref.current.getBoundingClientRect();
            scrollNavigatorRef.current.style.setProperty('--navigator-width', `${width}px`);
            wrapperref.current.style.setProperty('--image-height', `${height}px`);
            wrapperref.current.style.setProperty('--image-width', `${width}px`);
            wrapperref.current.dataset.width = `${width}`;
            wrapperref.current.dataset.height = `${height}`;
        }

        const doubleClickHandler = (e) => {
            if (e.target instanceof HTMLButtonElement) {
                return;
            }
            if (e.target.dataset.type === 'middle-area') {
                viewerChildRef?.current?.handleZoomToPoi(e);
            }
        };

        const zoomHandler = (e) => {
            if (
                !e.shiftKey &&
                e.target instanceof HTMLDivElement &&
                e.target.dataset.type !== 'middle-area'
            ) {
                return;
            }
            setTimeout(() => {
                viewerChildRef.current.handleZoom(e);
            });
        };

        const useMagnifyerOnKeyDownHandler = (e) => {
            if (e.shiftKey) {
                setUseMagnifyer(true);
            }
        };

        const useMagnifyerOnKeyUpHandler = (e) => {
            if (e.key === 'Shift') {
                setUseMagnifyer(false);
            }
        };

        if (!viewerConfig.zoomDisabled) {
            document.addEventListener('wheel', zoomHandler);
            document.addEventListener('dblclick', doubleClickHandler);
        }

        if (viewerConfig.magnifyerDisabled === false) {
            document.addEventListener('keydown', useMagnifyerOnKeyDownHandler);
            document.addEventListener('keyup', useMagnifyerOnKeyUpHandler);
        }

        return () => {
            document.removeEventListener('dbclick', doubleClickHandler);
            document.removeEventListener('wheel', zoomHandler);
            document.removeEventListener('keydown', useMagnifyerOnKeyDownHandler);
            document.removeEventListener('keyup', useMagnifyerOnKeyUpHandler);
        };
    }, []); // viewerConfig.zoomDisabled, viewerConfig.magnifyerDisabled

    const fullScreenHandler = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const element = event.currentTarget as HTMLButtonElement;
        // Turn of pressed allready here. User wont know.
        element.setAttribute('aria-pressed', 'false');

        if (viewerRef.current?.parentElement && document.fullscreenEnabled) {
            const parentContainer = viewerRef.current.parentElement;

            if (isFullScreen) {
                if (document.fullscreenElement) {
                    await document.exitFullscreen();
                }
            } else {
                await parentContainer.requestFullscreen({
                    navigationUI: 'hide',
                });
            }

            parentContainer.onfullscreenchange = async (_event: Event) => {
                // Had to hack this because the fullscreen API is not working as expected
                if (parentContainer.clientWidth > document.body.clientWidth) {
                    setIsFullscreen(!isFullScreen);
                }
            };
        }
    };

    const mouseMoveHandler = (e) => {
        const magnifyerLayerElement = e.target as HTMLDivElement;
        const { height, width } = magnifyerLayerElement.getBoundingClientRect();
        const percentageLeft = (e.nativeEvent.offsetX / width) * 100;
        const percentageTop = (e.nativeEvent.offsetY / height) * 100;
        const transformX = '-100';
        const transformY = '-100';
        wrapperref.current.style.setProperty('--transform-x', `${transformX}%`);
        wrapperref.current.style.setProperty('--transform-y', `${transformY}%`);
        viewerRef.current.style.setProperty('--mouse-x', `${e.nativeEvent.offsetX}`);
        viewerRef.current.style.setProperty('--mouse-y', `${e.nativeEvent.offsetY}`);
        viewerRef.current.style.setProperty('--percentage-left', `${percentageLeft}`);
        viewerRef.current.style.setProperty('--percentage-top', `${percentageTop}`);
    };

    const handleShareFalseDetectionFeedback = (feedbackDto: ImageFeedbackDto) => {
        feedbackDto.imageMetadataDto = currentVisibleImage ? currentVisibleImage : null;
        onCommitImageFeedbackDto(feedbackDto);
    };

    return (
        <div
            ref={wrapperref}
            className={styles.ImageViewContainer}
            data-imagewidth={imageViewObject?.dimensions?.height}>
            <div className={styles.controllbar}>
                <ImageMetaInfo
                    time_stamp={currentVisibleImage?.timeStamp}
                    numberOfImages={images.length}
                    index={imageIndex}
                    hasImages={images.length > 0}
                />
                <ImageViewerActionMenu initialActiveButtons={['magnifyer']}>
                    {images?.length > 0 && (
                        <ShareFalseDetectionDropdown
                            isOptoscaleAdmin={isOptoScaleAdmin}
                            onCommitFalseDetection={handleShareFalseDetectionFeedback}
                            showLiceOptions={instanceKey === 'licedetections'}
                            showHealthOptions={instanceKey === 'detections'}
                        />
                    )}

                    <ShareImage
                        isOptoscaleAdmin={isOptoScaleAdmin}
                        permalink={currentVisibleImage?.permalink ?? ''}
                        scpCommand={currentVisibleImage?.scpCommand ?? ''}>
                        <ShareImageTriggerButton />
                    </ShareImage>

                    {config.showHelpText && (
                        <div>
                            <OptoTooltip
                                content={'Show help text'}
                                nub="up-right"
                                active={useHelp}
                                customClass={styles.actionMenuToolTipStyles}>
                                <IconButton
                                    size={'small'}
                                    variant={'secondary'}
                                    onlyIcon={true}
                                    name={'help'}
                                    onClick={() => {
                                        setUseHelp(!useHelp);
                                    }}>
                                    <GenericIcon icon={iconUrls.HelpIconSVG} />
                                </IconButton>
                            </OptoTooltip>
                            <DropDownPanel open={useHelp}>
                                <ViewerHelpTextBlocks />
                            </DropDownPanel>
                        </div>
                    )}
                    {isColor && config.showPois && (
                        <OptoTooltip
                            content={t('Toggle fish health meta labels')}
                            nub="up-right"
                            customClass={styles.actionMenuToolTipStyles}>
                            <CheckBoxSwitch
                                label={<GenericIcon icon={iconUrls.LayerIconSVG} />}
                                checked={showPoiMeta}
                                onChangeHandler={() => {
                                    setShowPoiMeta(!showPoiMeta);
                                }}
                                name="pois"
                            />
                        </OptoTooltip>
                    )}

                    {viewerConfig.magnifyerDisabled === false && (
                        <OptoTooltip
                            content={t('Toggle inspection magnifyer')}
                            nub="up-right"
                            customClass={styles.actionMenuToolTipStyles}>
                            <CheckBoxSwitch
                                label={<GenericIcon icon={iconUrls.ZoomInIconSVG} />}
                                checked={useMagnifyer}
                                onChangeHandler={() => {
                                    setUseMagnifyer(!useMagnifyer);
                                }}
                                name="magnifyer"
                            />
                        </OptoTooltip>
                    )}
                    <DownloadImages imageList={images} imageIndex={imageIndex} />
                    <OptoTooltip
                        content="Show fish viewer in fullscreen"
                        nub="up-right"
                        customClass={styles.actionMenuToolTipStyles}>
                        <IconButton
                            name="fullscreen"
                            type="button"
                            size={'small'}
                            variant={'secondary'}
                            onlyIcon={true}
                            onClick={fullScreenHandler}>
                            {isFullScreen ? (
                                <GenericIcon icon={iconUrls.FullScreenExitconSVG} />
                            ) : (
                                <GenericIcon icon={iconUrls.FullScreenIconSVG} />
                            )}
                        </IconButton>
                    </OptoTooltip>
                </ImageViewerActionMenu>
            </div>
            <ImageControl handleOnClick={handleOnClick}>
                <main>
                    <div className={styles.tooltip} ref={toolTipref} />
                    {useMagnifyer && (
                        <div
                            className={styles.magnifyerLayer}
                            ref={magnifyerLayerRef}
                            onMouseMove={mouseMoveHandler}
                        />
                    )}

                    <section className={styles.swipeWindow} ref={viewerRef}>
                        {images.length > 0 && (
                            <ImageViewer
                                useMagnifyer={useMagnifyer}
                                showPoiMeta={showPoiMeta}
                                {...imageViewObject}
                                poiPositionHandler={poiPositionHandler}
                                ref={viewerChildRef}
                                instanceKey={instanceKey}
                                isOptoScaleAdmin={isOptoScaleAdmin}
                            />
                        )}
                    </section>
                    <div className={styles.overlay} ref={overlayRef}>
                        <div className={styles.overLayHeader} />
                        <div data-type="middle-area" className={styles.middleArea} {...handlers} />
                    </div>
                </main>
            </ImageControl>
            <div className={styles.overLayFooter} ref={scrollNavigatorRef}>
                <ScrollNavigator
                    instanceKey={instanceKey}
                    listOfDates={dayswithimages}
                    imageIndex={imageIndex}
                    onAfterTimelineChangeHandler={onAfterTimelineChangeHandler}
                    currentVisibleDay={currentVisibleImage?.timeStamp}>
                    {navigationCardInfoObjects.map((imageInfo, index) => {
                        return (
                            <FishHealthNavigationCard
                                key={`${imageInfo.imageId}${index}`}
                                imagePath={imageInfo.path}
                                healing={imageInfo.healing}
                                active={imageInfo.active}
                                imageId={imageInfo.imageId}
                                laksvelScores={imageInfo.laksvelScores}
                                currentVisibleImageId={currentVisibleImage?.id}
                                onClickHandler={handleFishNavigation}
                                showWoundLabel={config.showWoundLabel}
                                showLiceLabel={config.showLiceLabel}
                                showLaksvelLabel={config.showLaksvelLabel}
                                skottelus={imageInfo.skottelus}
                                adult_female_lice={imageInfo.adult_female_lice}
                                movable={imageInfo.movable}
                            />
                        );
                    })}
                </ScrollNavigator>
            </div>
        </div>
    );
};

export default ImageViewContainer;
export { ImageViewContainer };
