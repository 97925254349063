import React from 'react';
import { useTranslation } from 'react-i18next';
import { DailyLiceCount, ImageFeedbackDto, Last3DaysResult, WeeklyLiceCount } from 'services/types';

import Loader from '@/components/atoms/Loader';
import Notice from '@/components/atoms/Notice/Notice';
import UmerBox from '@/components/atoms/UmerBox';
import { ImageViewContainer } from '@/components/organisms/ImageViewer';
import { ImageState } from '@/hooks/image-state';

import AdultLiceTemperatureGraph from './components/AdultLiceTemperatureGraph';
import LiceCountBarChart from './components/LiceCountBarChart/LiceCountBarChart';
//import LiceOverview from './components/LiceOverview';
import LiceMeanPerFishGraph from './components/LiceMeanPerFishGraph/LiceMeanPerFishGraph';
import LiceStatus from './components/LiceStatus';

import './LiceCount.scss';

//when changing the name in side GRAPH_LABELS_YAXIS array,
//should also change the name in AdultLiceTemperatureGraph.jsx file
const GRAPH_LABELS = [
    {
        label: 'Adult Female Lice',
        axis: 'y-axis-left',
        dataLabel: 'femaleAvg',
        licenseMaxRequired: false,
    },
    {
        label: 'Mobile Lice',
        alxis: 'y-axis-left',
        dataLabel: 'movingStageAvg',
        licenseMaxRequired: true,
    },
    {
        label: 'Caligus elongatus',
        axis: 'y-axis-left',
        dataLabel: 'caligusElongatusAvg',
        licenseMaxRequired: true,
    },
    {
        label: 'Temperature',
        axis: 'y-axis-right',
        dataLabel: 'temperature',
        licenseMaxRequired: true,
    },
] as const;

const COLORS = ['#5bb784', '#9dd4b6', '#354d78', 'rgba(203, 217, 252, 0.4)'];

interface LiceCountProps {
    last200: Last3DaysResult & {
        count?: number;
        countNoLice?: number;
        dayPart?: string;
        daypartId?: number;
        total?: number;
    };
    showMaximumLiceInformation: boolean;
    liceCount: DailyLiceCount[];
    liceCountOverviewWeekly: WeeklyLiceCount[];
    showLiceImagesForClient?: boolean;
    isOptoscaleAdmin?: boolean;
    measurementSource?: 'raw' | 'legacy';
    imageState: ImageState;
    onCommitImageFeedbackDto?: (imageFeedbackDto: ImageFeedbackDto) => void;
}

const LiceCount = ({
    last200,
    liceCount,
    liceCountOverviewWeekly,
    showMaximumLiceInformation,
    isOptoscaleAdmin = false,
    onCommitImageFeedbackDto = (imageFeedbackDto: ImageFeedbackDto) => {},
    imageState,
}: LiceCountProps) => {
    const { t } = useTranslation();
    const graphLabels = GRAPH_LABELS.filter((licenseCheck) => {
        if (!licenseCheck.licenseMaxRequired) return true;
        if (showMaximumLiceInformation) return showMaximumLiceInformation;
        return false;
    });

    const lastFemaleAvg = liceCount[liceCount?.length - 1]?.femaleAvg;
    const SHOW_LICE_IMAGE_LIMIT = 0.02;
    const showLiceImages = lastFemaleAvg >= SHOW_LICE_IMAGE_LIMIT;

    return (
        <div>
            <UmerBox>
                <LiceStatus
                    liceInMin200Fish={last200}
                    showMaximumLiceInformation={showMaximumLiceInformation}
                />
            </UmerBox>
            <UmerBox>
                <AdultLiceTemperatureGraph
                    data={liceCount}
                    colors={COLORS}
                    GRAPH_LABELS={graphLabels}
                />
            </UmerBox>

            <UmerBox>
                <LiceCountBarChart liceCount={liceCount} />
            </UmerBox>

            <UmerBox>
                {showLiceImages || isOptoscaleAdmin ? (
                    <Loader loading={imageState.loading}>
                        <ImageViewContainer
                            instanceKey={'licedetections'}
                            isOptoScaleAdmin={isOptoscaleAdmin}
                            onCommitImageFeedbackDto={onCommitImageFeedbackDto}
                            viewerConfig={{
                                showPois: true,
                                showWoundLabel: false,
                                zoomDisabled: true,
                                showLiceLabel: true,
                                showMagnifyer: false,
                                scrollActivated: true,
                                magnifyerDisabled: false,
                                showHelpText: false,
                            }}
                            {...imageState}
                        />
                    </Loader>
                ) : (
                    <Notice
                        heading={t('No lice images heading')}
                        message={t('No lice images message')}
                        variant="warning"
                    />
                )}
            </UmerBox>
            <UmerBox>
                <LiceMeanPerFishGraph
                    liceCount={liceCount}
                    liceCountOverviewWeekly={liceCountOverviewWeekly}
                    showMaximumLiceInformation={showMaximumLiceInformation}
                />
            </UmerBox>
        </div>
    );
};

export default LiceCount;
export { LiceCount };
