import React from 'react';
import { v4 as uuid4 } from 'uuid';

import styles from './SplitValueElement.module.scss';

export interface SplitValueElementProps {
    children?: (React.ReactNode | number | string)[];
    variant?: 'default' | 'highlight';
}

const SplitValueElement = ({ children = [], variant = 'default' }: SplitValueElementProps) => {
    return (
        <div className={styles.splitvalueelement} data-variant={variant}>
            {children.map((child, index) => {
                const id = uuid4();
                return (
                    <span data-dir={index === 0 ? 'right' : 'left'} data-type="splitvalue" key={id}>
                        {child}
                    </span>
                );
            })}
        </div>
    );
};

export default SplitValueElement;
export { SplitValueElement };
