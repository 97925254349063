import React, { useState } from 'react';
import { Input, Label } from 'reactstrap';

import { Button } from '@/components/atoms/Buttons';
import NoData from '@/components/NoData';

import styles from './WinchControl.module.scss';

export interface WinchControlProps {
    bioscope?: { id: number }; // Assuming bioscope prop contains an id
    bioscopeData: any;
    handlePublish: (topic: string, message: string) => void;
}

const WinchControl = ({ bioscope, bioscopeData, handlePublish }: WinchControlProps) => {
    const [depth, setdepth] = useState('');
    const title = 'Winch Status & Control';

    const handleMove = () => {
        const topic = `winch/control/${bioscope?.id}`;
        const message = JSON.stringify({ action: 'move', depth: parseFloat(depth) });
        handlePublish(topic, message);
    };

    const handleStop = () => {
        const topic = `winch/control/${bioscope?.id}`;
        const message = JSON.stringify({ action: 'stop' });
        handlePublish(topic, message);
    };

    return (
        <div className={styles.winchcontrol}>
            <h2>{title}</h2>
            <div className="mt-3">
                {bioscopeData ? (
                    <div>
                        <strong>Current Depth:</strong>{' '}
                        {bioscopeData.Depth ? bioscopeData.Depth : 'N/A'}
                    </div>
                ) : (
                    <NoData color={'warning'} />
                )}
            </div>
            <div className="mt-3">
                <form>
                    <Label for="depthInput">Enter the depth required in meters</Label>
                    <Input
                        id="depthInput"
                        type="text"
                        value={depth}
                        onChange={(e) => {
                            const value = e.target.value;
                            if (/^\d*\.?\d*$/.test(value)) {
                                // Allow only numbers and decimal points
                                setdepth(value);
                            }
                        }}
                        placeholder="eg. 10.5"
                        className={`form-control ${
                            depth && (parseFloat(depth) < 4 || parseFloat(depth) > 20)
                                ? 'is-invalid'
                                : 'is-valid'
                        }`}
                    />
                </form>
            </div>
            {depth && (parseFloat(depth) < 4 || parseFloat(depth) > 20) && (
                <div className="text-danger">Depth must be between 4 and 20 meters.</div>
            )}

            <div className="mt-3">
                <Button type="button" onClick={handleMove}>
                    Move
                </Button>
                <Button type="button" onClick={handleStop}>
                    Stop
                </Button>
            </div>
        </div>
    );
};

export default WinchControl;
export { WinchControl };
