import type React from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import LICENSES from 'config/licenses';
import type { CageContextType } from 'contexts/cage-context';

import { ImagePageContainer } from '../pages/Images/ImagePageContainer';

import { FishHealthColorContainer } from './FishHealth/FishHealthColorContainer';
import { FishSpeedPage } from './Fishspeed/FishSpeed';
import LaksvelContainerRouter from './Laksvel/LaksvelContainerRouter';
import LaksvelRoutes from './Laksvel/LaksvelRoutes';
// Adjust the import path as necessary
import CageBioscopeDetailContainer from './CageBioscopeDetail';
import Graphs2 from './Graphs';
import LiceCount from './LiceCount';
import Overview2 from './Overview2';
import { ReportDetailContainer, ReportListContainer } from './ReportListImproved';
import Settings from './Settings';
import WeightDistribution from './WeightDistribution';

/** Convenience export of the H.Location type for use with useLocation */
export type UseLocationType = RouteComponentProps['location'];

/** Convenience export of the H.History type for use with useHistory */
export type UseHistoryType = RouteComponentProps['history'];

export interface Page {
    path?: string;
    label?: string;
    page?: React.ComponentType;
    roles?: string[];
    hidden?: boolean | ((ctx: CageContextType) => boolean);
    exact?: boolean;
    licenses?: string[];
    key: number;
    params?: {
        [key: string]: string;
    };
}

export const isHidden = (page: Page, ctx: CageContextType) => {
    if (typeof page.hidden === 'boolean') {
        return page.hidden;
    }
    if (typeof page.hidden === 'function') {
        if (ctx.loading) {
            return true;
        }
        return page.hidden(ctx);
    }
    return false;
};

export type Pages = Page[];

const pages: Pages = [
    {
        path: '',
        label: 'Overview',
        page: Overview2,
    },
    {
        path: '/bioscope-image',
        label: 'Images',
        page: ImagePageContainer,
    },
    {
        path: '/lice-count',
        label: 'Lice',
        page: LiceCount,
        licenses: [LICENSES.LICE_COUNT.MIN, LICENSES.LICE_COUNT.MAX],
    },
    {
        path: '/health/wounds',
        label: 'Health',
        exact: true,
        page: FishHealthColorContainer,
        licenses: [LICENSES.FISH_HEALTH.MIN],
    },
    {
        path: '/welfare',
        label: 'Welfare',
        exact: false,
        page: LaksvelContainerRouter,
        hidden: false,
        licenses: [LICENSES.LAKSVEL],
    },

    // Laksvel subpages

    ...LaksvelRoutes.map((item) => ({
        exact: true,
        label: item.label,
        page: LaksvelContainerRouter,
        path: `/welfare${item.path}`,
        hidden: true,
        licenses: [LICENSES.LAKSVEL],
    })),

    {
        path: '/graphs',
        label: 'Graphs',
        page: Graphs2,
        exact: true,
    },

    {
        path: '/graphs/speed',
        label: 'Fish speed',
        page: FishSpeedPage,
        exact: true,
        hidden: true,
    },

    {
        path: '/weight-distribution',
        label: 'Weight distribution',
        page: WeightDistribution,
    },

    {
        exact: true, // because we have children
        path: '/reports/v2',
        label: 'Reporting.Reporting',
        page: ReportListContainer,
    },

    {
        path: '/reports/v2/:reportId',
        page: ReportDetailContainer,
    },

    {
        path: '/settings',
        label: 'Settings',
        page: Settings,
    },

    {
        path: '/health/:healthType',
        label: 'Health',
        exact: true,
        hidden: true,
        page: FishHealthColorContainer,
        licenses: [LICENSES.FISH_HEALTH.MIN],
    },

    {
        path: '/bioscope/:bioscopeId',
        label: 'Bioscope',
        exact: true,
        hidden: true,
        page: CageBioscopeDetailContainer,
    },
].map((page, index) => ({ key: index, ...page }));

export default pages;
export { pages };
