import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { useCage } from '@/contexts/cage-context';
import { formattedTs } from '@/utils/formattedTs';

import styles from './tinyTemplates.module.scss';

const formatterTemplates = {
    fullDateTime: 'D-M-YYYY HH:mm:ss',
    date: 'D-M-YYYY',
    time: 'HH:mm:ss',
    daysAgo: 'D [days ago]',
    dateTime: 'HH:mm, DD/MM/YY',
};

export interface TimeStampAsToolTipProps {
    timeStamp: string | number | undefined;
    children?: ReactNode;
    timeFormat?: 'fullDateTime' | 'date' | 'time' | 'daysAgo' | 'dateTime';
}

const TimeStampAsToolTip = ({
    timeStamp,
    timeFormat = 'fullDateTime',
    children = null,
}: TimeStampAsToolTipProps) => {
    const { t } = useTranslation();
    const { timezone } = useCage();

    const selectedFormat = formatterTemplates[timeFormat];

    const tZone = timezone === undefined ? 'UTC' : timezone;

    const renderTime = formattedTs(new Date(timeStamp), tZone, {
        format: selectedFormat,
    });

    const dateOrFallback = renderTime === 'Invalid date' ? t('time unknown') : renderTime;

    return (
        <span className={styles.timeStampTemplate}>
            <span>{t('Last measurement')}:</span>
            <span>{dateOrFallback}</span>
            {children && <span>{children}</span>}
        </span>
    );
};

export default TimeStampAsToolTip;
export { TimeStampAsToolTip, formatterTemplates };
