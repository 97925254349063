import React, { useEffect } from 'react';
import { toast, ToastOptions, ToastPosition } from 'react-toastify';
import { mergeDeep } from 'immutable';

import { GenericIcon } from '@/components/atoms/icons/GenericIcon';
import {
    FishHealthIconSVG,
    HomeIconSVG,
    LaksvelShieldSVG,
    LiceIconSVG,
    VideoCameraIconSVG,
    WinchIconSVG,
} from '@/components/atoms/icons/GenericIcon/GenericIcons';
import { useMe } from '@/contexts/meContext';

import styles from './ToastTemplate.module.scss';

/**
 * DOCS: https://fkhadra.github.io/react-toastify/introduction
 */

const LICENSES = {
    FISH_HEALTH_DATA: 'FISH_HEALTH.DATA',
    FISH_HEALTH_MIN: 'FISH_HEALTH.MIN',
    LICE_COUNT_MIN: 'LICE_COUNT.MIN',
    LICE_COUNT_DATA: 'LICE_COUNT.DATA',
    LICE_COUNT_MAX: 'LICE_COUNT.MAX',
    FISH_HEALTH_INSPECTION: 'FISH_HEALTH.INSPECTION',
    BIOMASS_FULL_: 'BIOMASS.FULL',
    FISH_SPEED: 'FISH_SPEED',
};

const ROLES = {
    OPTOSCALE_ADMIN: 'OptoScaleAdmin',
    CLIENT_ADMIN: 'ClientAdmin',
    LOCATION_ADMIN: 'LocationAdmin',
};

const ICONS = {
    lice: LiceIconSVG,
    health: FishHealthIconSVG,
    fishspeed: HomeIconSVG,
    optoscale: HomeIconSVG,
    winch: WinchIconSVG,
    Laksvel: LaksvelShieldSVG,
    video: VideoCameraIconSVG,
};

export interface ToastTemplateBackendFeedbackProps {
    httpStatusCode?: number;
    toastId?: string;
    content: string | React.ReactNode;

    position?:
        | 'top-left'
        | 'top-center'
        | 'top-right'
        | 'bottom-left'
        | 'bottom-center'
        | 'bottom-right';
}

const ToastTemplateBackendFeedback = ({
    httpStatusCode = 200,
    toastId = 'default',
    content,
    position = 'top-right',
}: ToastTemplateBackendFeedbackProps) => {
    const { isOptoscaleAdmin } = useMe();

    if (httpStatusCode === 200 || httpStatusCode === 201) {
        toast.warn(content, {
            toastId: toastId,
            containerId: 'optoscale-toast',
            position: position,
            autoClose: 8000,
            closeOnClick: true,
            hideProgressBar: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    } else {
        toast.error('Failed to submit feedback', {
            toastId: toastId,
            containerId: 'optoscale-toast',
            position: position,
            autoClose: 8000,
            closeOnClick: true,
            hideProgressBar: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    return <></>;
};

interface ToastInfoTemplateProps {
    content: string | React.ReactNode;
    position?:
        | 'top-left'
        | 'top-center'
        | 'top-right'
        | 'bottom-left'
        | 'bottom-center'
        | 'bottom-right';
    type?: 'info' | 'success' | 'warning' | 'error';
    module?: 'lice' | 'health' | 'fishspeed' | 'general';
    page?: string;
    license?: string;
    icon:
        | 'noicon'
        | 'lice'
        | 'health'
        | 'fishspeed'
        | 'optoscale'
        | 'winch'
        | 'Laksvel'
        | 'video'
        | string;
    role?: string;
    options?: ToastOptions<unknown> | undefined;
    location?: Location;
    status?: 'warning' | 'error' | 'success' | 'info';
}

const ToastInfoTemplate = ({
    content,
    type = 'info',
    license = 'all',
    role = 'all',
    page = 'all',
    module = undefined,
    location = window.location as Location,
    options = {},
    icon = 'noicon',
    status = 'info',
}: ToastInfoTemplateProps) => {
    //const location = useLocation();

    const iconColors = {
        warning: '#f1c40f',
        error: '#e74c3c',
        success: '#07bc0c',
        info: '#121212',
    };

    const defaultOptions = {
        toastId: 'opto-info',
        containerId: 'optoscale-toast',
        position: 'top-right' as ToastPosition,
        autoClose: false as number | false,
        closeOnClick: true,
        hideProgressBar: false,
        pauseOnHover: true,
        bodyClassName: styles.optoscale_toast_styles,
        delay: 20,
        draggable: true,
        progress: undefined,
        type: type,
        icon: () => {
            const iconUrl = Object.keys(ICONS).includes(icon)
                ? ICONS[icon as keyof typeof ICONS]
                : icon;
            return <GenericIcon icon={iconUrl} color={iconColors[status]} />;
        },
    };

    useEffect(() => {
        const pathParts = location.pathname.split('/');
        const match = pathParts.find((part: string) => part === page);

        if (match === undefined) {
            toast.dismiss({ id: defaultOptions.toastId, containerId: defaultOptions.containerId });
        }

        return () => {
            toast.dismiss({ id: defaultOptions.toastId, containerId: defaultOptions.containerId });
        };
    }, [location, page, defaultOptions.toastId, defaultOptions.containerId]);

    toast(
        content,
        options !== undefined
            ? mergeDeep(defaultOptions, options as { [key: string]: unknown })
            : defaultOptions
    );

    return <></>;
};

const ToastInfo = ({
    content,
    type = 'info',
    license = 'all',
    role = 'all',
    page = 'all',
    icon = 'noicon',
    module = undefined,
    location = window.location as Location,
    options = {},
    status = 'info',
}: ToastInfoTemplateProps) => {
    const iconColors = {
        warning: '#f1c40f',
        error: '#e74c3c',
        success: '#07bc0c',
        info: '#121212',
    };

    const defaultOptions = {
        toastId: 'opto-info',
        containerId: 'optoscale-toast',
        position: 'top-right' as ToastPosition,
        autoClose: false as number | false,
        closeOnClick: true,
        hideProgressBar: false,
        pauseOnHover: true,
        delay: 20,
        draggable: true,
        progress: undefined,
        bodyClassName: styles.optoscale_toast_styles,
        type: type,

        icon: () => {
            const iconUrl = Object.keys(ICONS).includes(icon)
                ? ICONS[icon as keyof typeof ICONS]
                : icon;
            return <GenericIcon icon={iconUrl} color={iconColors[status]} />;
        },
    };

    const pathParts = location.pathname.split('/');
    const match = pathParts.find((part: string) => part === page);

    if (match === undefined) {
        toast.dismiss({ id: defaultOptions.toastId, containerId: defaultOptions.containerId });
    }

    toast(
        content,
        options !== undefined
            ? mergeDeep(defaultOptions, options as { [key: string]: unknown })
            : defaultOptions
    );
};

export default ToastInfoTemplate;
export { ToastTemplateBackendFeedback, ToastInfoTemplate, ToastInfo };
