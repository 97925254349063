export const GrowthValue = (growth: number | undefined, daypartLocalizedText: string) => {
    return growth ? `${Math.floor(growth)} g/${daypartLocalizedText}` : '---';
};

export const WoundsValue = (wound: number | undefined) => {
    if (wound) {
        return `${(100 * Math.round(wound * 200)) / 200}%`;
    }
    return '---';
};

export const LiceValue = (lice: number | undefined) => {
    if (lice) {
        return `${lice?.toFixed(2)}`;
    }
    return '---';
};

export const LaksvelValue = (avgLaksvel: number | undefined) => {
    if (avgLaksvel) {
        return `${avgLaksvel?.toFixed(1)}%`;
    }
    return '---';
};
