import type React from 'react';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { IconButton } from '../Buttons';

import styles from './CheckBoxSwitch.module.scss';

export interface CheckBoxSwitchProps {
    children?: React.ReactNode;
    label: string | React.ReactNode;
    checked?: boolean;
    asButton?: boolean;
    name?: string;
    onChangeHandler?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CheckBoxSwitch = ({
    children,
    label,
    checked = false,
    asButton = true,
    name = 'checkboxswitch',
    onChangeHandler,
}: CheckBoxSwitchProps) => {
    const id = uuidv4();
    const [isChecked, setIsChecked] = useState<boolean>(checked);

    useEffect(() => {
        setIsChecked(checked);
    }, [checked]);

    return (
        <div className={styles.checkboxswitch} data-asbutton={asButton}>
            <input
                className={asButton ? styles.asButton : ''}
                type="checkbox"
                checked={isChecked}
                name={name}
                id={id}
                onChange={(event) => {
                    setIsChecked(event.target.checked);
                    const syntheticEvent = {
                        ...event,
                        target: {
                            ...event.target,
                            checked: !isChecked,
                        },
                    } as unknown as React.ChangeEvent<HTMLInputElement>;
                    onChangeHandler?.(syntheticEvent);
                }}
            />
            <label htmlFor={id} className={asButton ? styles.asButtonLabel : ''}>
                {asButton ? (
                    <IconButton
                        size="small"
                        onlyIcon={true}
                        name={name}
                        onClick={(event) => {
                            setIsChecked(!isChecked);
                            const syntheticEvent = {
                                ...event,
                                target: {
                                    ...event.target,
                                    checked: !isChecked,
                                },
                            } as unknown as React.ChangeEvent<HTMLInputElement>;
                            onChangeHandler?.(syntheticEvent);
                        }}>
                        {label}
                    </IconButton>
                ) : (
                    label
                )}
            </label>
            {children}
        </div>
    );
};

export default CheckBoxSwitch;
export { CheckBoxSwitch };
