import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useMqtt } from '@/contexts/mqtt-provider-context';
import { useLocationController_LocationBioscopes } from '@/services/hooks';
import { CagesWithActiveBioscopesByLocationDto } from '@/services/types';

import { LocationWinchPageContent } from './LocationWinchPageContent';
import { mqttTopics } from './MqttTopics';

export interface LocationWinchProps {
    clientId: number;
    locationId: number;
}

const LocationWinch = ({ locationId }: LocationWinchProps) => {
    const { t } = useTranslation();

    const { client, isConnected, publish, subscribe, unsubscribe } = useMqtt();
    const [currentTopics, setCurrentTipcs] = useState<{
        [key: number]: ReturnType<typeof mqttTopics>;
    }>({});
    const [bioScopes, setBioScopes] = useState<CagesWithActiveBioscopesByLocationDto[]>([]);

    const {
        data: locationBioscopes,
        error: locationBioscopesError,
        isLoading: isLoadingLocationBioscopes,
    } = useLocationController_LocationBioscopes(locationId, {
        staleTime: 5 * 60 * 1000,
        cacheTime: 5 * 60 * 1000,
    });

    if (locationBioscopesError) {
        console.error(locationBioscopesError);
    }

    useEffect(() => {
        if (
            !isLoadingLocationBioscopes &&
            locationBioscopes?.data &&
            locationBioscopes.data.length > 0
        ) {
            const locationBioscopesData: CagesWithActiveBioscopesByLocationDto[] =
                locationBioscopes.data;

            // Adding test winch to demo location pen.
            if (locationId === 596) {
                locationBioscopesData[0] = {
                    ...(locationBioscopesData.find((cage) => cage.cageId === 6952) ||
                        locationBioscopesData[0]),
                    bioscopeId: 1323,
                };
            }

            setBioScopes(locationBioscopes.data);
        }
    }, [locationId, locationBioscopes, isLoadingLocationBioscopes]);

    useEffect(() => {
        if (isConnected && bioScopes && !isLoadingLocationBioscopes) {
            const bioscopeIds: number[] = [];
            // Adding test winch to demo location pen.
            if (locationId === 596) {
                bioscopeIds.push(1323);
            }
            for (const bioscope of bioScopes) {
                for (const bioscopeId of [bioscope.bioscopeId]) {
                    if (!bioscopeIds.includes(bioscopeId)) {
                        bioscopeIds.push(bioscopeId);
                    }
                }
            }

            const topicsForAllBioscopes: { [key: number]: ReturnType<typeof mqttTopics> } = {};

            for (const id of bioscopeIds) {
                const topics = mqttTopics(id);
                if (topics) {
                    topicsForAllBioscopes[id] = topics;
                    for (const topic of Object.keys(topics.subscribe_topics)) {
                        subscribe(
                            topics.subscribe_topics[topic as keyof typeof topics.subscribe_topics],
                            {
                                qos: 1,
                            }
                        );
                    }
                }
            }

            setCurrentTipcs(topicsForAllBioscopes);

            return () => {
                // Unsubscript from all topics
                for (const topicsForBioscope in Object.keys(currentTopics)) {
                    try {
                        const topics = currentTopics[topicsForBioscope];
                        if (!topics) {
                            continue;
                        }
                        for (const topic in Object?.keys(topics?.subscribe_topics)) {
                            console.log('Unsubscribing from topic:', topic);
                            unsubscribe(
                                topics.subscribe_topics[
                                    topic as keyof typeof topics.subscribe_topics
                                ]
                            );
                        }
                    } catch (error) {
                        console.error(error);
                    }
                }
            };
        }
        isConnected === false && client?.connected && client.end();
    }, [client, isConnected, bioScopes, locationBioscopes, isLoadingLocationBioscopes]);

    return (
        <>
            <LocationWinchPageContent
                title={t('location.winch.title')}
                mqttClient={client}
                locationBioScopes={bioScopes}
                currentTopic={currentTopics as Record<number, ReturnType<typeof mqttTopics>>}
            />
        </>
    );
};

export default LocationWinch;
export { LocationWinch };
