import React from 'react';

import { Button } from '@/components/atoms/Buttons';
import NoData from '@/components/NoData';

import styles from './BioscopeSystemReadings.module.scss';

export interface BioscopeSystemReadingsProps {
    children?: React.ReactNode;
    bioscope?: { id: number }; // Assuming bioscope prop contains an id
    bioscopeData: any;
    handlePublish: (topic: string, message: string) => void;
}

const BioscopeSystemReadings = ({
    children,
    bioscope,
    bioscopeData,
    handlePublish,
}: BioscopeSystemReadingsProps) => {
    const handleRefresh = () => {
        const topic = `bioscope/data/${bioscope?.id}`;
        const message = 'fetch_bioscope_data';
        handlePublish(topic, message);
    };

    return (
        <div className={styles.bioscopesystemreadings}>
            <h2>{'Bioscope System Readings'}</h2>
            {children}
            <div className="mt-3">
                <Button type="button" onClick={handleRefresh}>
                    Refresh
                </Button>
            </div>
            <div className="mt-3">
                {bioscopeData ? (
                    <table className="text-nowrap">
                        <tbody>
                            {Object.keys(bioscopeData).map((key) => (
                                <tr key={key}>
                                    <td>
                                        <strong>{key}</strong>
                                    </td>
                                    <td>
                                        {key.toLowerCase().includes('timestamp')
                                            ? new Date(bioscopeData[key]).toLocaleString()
                                            : bioscopeData[key]}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <NoData color={'warning'} />
                )}
            </div>
        </div>
    );
};

export default BioscopeSystemReadings;
export { BioscopeSystemReadings };
