import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import IconButton from '@/components/atoms/Buttons/IconButton';
import { GenericIcon } from '@/components/atoms/icons/GenericIcon';
import * as iconUrls from '@/components/atoms/icons/GenericIcon/GenericIcons';
import OptoTooltip from '@/components/atoms/OptoTooltip';

import DropDownPanel from '../DropDownPanel';
import { DropDownPanelProps } from '../DropDownPanel/DropDownPanel';

import styles from './ShareImage.module.scss';

export interface ShareImageProps extends DropDownPanelProps {
    permalink?: string;
    scpCommand?: string;
    isOptoscaleAdmin?: boolean;
    children?: React.ReactNode;
}
// 997204

const ShareImage = ({
    permalink = '',
    scpCommand = '',
    isOptoscaleAdmin = false,
    open = false,
    children = null,
}: ShareImageProps) => {
    const { t } = useTranslation();
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(open);
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const copyToClipboardHandler = (text: string) => {
        navigator.clipboard.writeText(text).then(
            () => {
                console.log('Copied to clipboard');
            },
            (err) => {
                console.error('Failed to copy to clipboard', err);
            }
        );
    };

    return (
        <div className={styles.wrapper}>
            <ShareImageTriggerButton sharePanelToggleHandler={toggle} />
            <DropDownPanel open={dropdownOpen}>
                <div className={styles.shareImagePanel}>
                    <fieldset className={styles.shareImage_content}>
                        <legend>{t('Copy image url to this image')}</legend>
                        <div data-type="formrow">
                            <input
                                type="text"
                                value={permalink}
                                readOnly
                                onClick={(e) => {
                                    e.currentTarget.select();
                                }}
                            />
                            <IconButton
                                buttonText={t('Copy')}
                                variant="secondary"
                                onClick={() => {
                                    copyToClipboardHandler(permalink);
                                }}>
                                <GenericIcon icon={iconUrls.CopyIconSVG} />
                            </IconButton>
                        </div>
                    </fieldset>
                    {isOptoscaleAdmin && scpCommand.length > 0 && (
                        <fieldset className={styles.shareImage_content}>
                            <legend>{t('Copy SCP command for image download')}</legend>
                            <div data-type="formrow">
                                <input
                                    type="text"
                                    value={scpCommand}
                                    readOnly
                                    onClick={(e) => {
                                        e.currentTarget.select();
                                    }}
                                />
                                <IconButton
                                    buttonText={t('Copy')}
                                    variant="secondary"
                                    onClick={() => {
                                        copyToClipboardHandler(scpCommand);
                                    }}>
                                    <GenericIcon icon={iconUrls.CopyIconSVG} />
                                </IconButton>
                            </div>
                        </fieldset>
                    )}
                </div>
            </DropDownPanel>
        </div>
    );
};

export interface ShareImageTriggerButtonProps {
    sharePanelToggleHandler: () => void;
}
const ShareImageTriggerButton = ({ sharePanelToggleHandler = () => {} }) => {
    const { t } = useTranslation();
    return (
        <>
            <OptoTooltip content={t('Copy image url to this image')} nub={'up-right'}>
                <IconButton
                    variant="secondary"
                    onClick={sharePanelToggleHandler}
                    size={'small'}
                    onlyIcon={true}
                    name={'share'}>
                    <GenericIcon icon={iconUrls.ShareIconSVG} />
                </IconButton>
            </OptoTooltip>
        </>
    );
};

export default ShareImage;
export { ShareImage, ShareImageTriggerButton };
