import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { NavbarBrand } from 'reactstrap';
import cn from 'classnames';

import { BackIcon } from '@/components/atoms/icons/Back';
import { LeftColumn, Navigation } from '@/components/Layout';
import {
    GenericSideMenuBackOption,
    SidebarType,
} from '@/components/molecules/GenericSidebar/index';
import { SidebarPageOptionObject } from '@/components/molecules/GenericSidebar/SidebarPageOptionObjects';
import { GenericSidebarItem } from '@/components/molecules/GenericSidebarItem';
import { joinUrlPath } from '@/utils/urlPath';

import styles from './GenericSidebar.module.scss';

export interface LocationSidebarProps {
    children?: React.ReactNode;
    sideBarOptions?: SidebarPageOptionObject[];
    sidebarType?: SidebarType;
    layerUrlPrefix?: string; // if the use route does not work for this level, inject the url prefix instead
    backOption?: GenericSideMenuBackOption;
}

const GenericSidebar = ({
    children,
    sideBarOptions,
    sidebarType = SidebarType.locationLevel,
    layerUrlPrefix = null,
    backOption = null,
}: LocationSidebarProps) => {
    const { t } = useTranslation();

    const location = useLocation();
    const match = useRouteMatch();
    const history = useHistory();

    const [currentSideBarOptions, setCurrentSideBarOptions] = useState<
        SidebarPageOptionObject[] | undefined
    >([]);

    const [openSidebarOptions, setOpenSidebarOptions] = useState<SidebarPageOptionObject[]>([]);

    const filterAndConstructSidebarOptions = (sideBarOptions: SidebarPageOptionObject[]) => {
        let compareActiveUrl = location.pathname;
        if (compareActiveUrl.endsWith('/')) {
            compareActiveUrl = compareActiveUrl.slice(0, -1);
        }
        return sideBarOptions
            .filter((item) => item?.page?.sidebarToShowFor === sidebarType)
            .map((sideBarOption) => {
                let url = '';
                if (layerUrlPrefix) {
                    if (sideBarOption.page?.path) {
                        url = `${layerUrlPrefix}/${sideBarOption.page?.path}`;
                        url = joinUrlPath(layerUrlPrefix, sideBarOption.page?.path);
                    } else {
                        url = layerUrlPrefix;
                    }
                } else {
                    url = joinUrlPath(match?.url, sideBarOption.page?.path);
                }

                return {
                    ...sideBarOption,
                    url: url,
                    active: url === compareActiveUrl,
                };
            });
    };

    const handleSetOpenSideOptions = (sideOption: SidebarPageOptionObject) => {
        let tempSideBarOptions: SidebarPageOptionObject[] = [...openSidebarOptions];

        if (sideOption.page.parentId === undefined) {
            tempSideBarOptions = [];
        }

        const shouldAdd = !tempSideBarOptions.some((item) => {
            return item.page === sideOption.page;
        });

        if (shouldAdd) {
            tempSideBarOptions.push(sideOption);
        }

        setOpenSidebarOptions(tempSideBarOptions);
    };

    useEffect(() => {
        setCurrentSideBarOptions(filterAndConstructSidebarOptions(sideBarOptions));
    }, []);

    useEffect(() => {
        setCurrentSideBarOptions(filterAndConstructSidebarOptions(sideBarOptions));
    }, [sideBarOptions, location.pathname, match, sidebarType]);

    const handleGoBack = () => {
        if (backOption) {
            history.push(backOption.url);
        }
    };
    return (
        <LeftColumn>
            <nav className={styles.leftNavigationPanel}>
                <NavbarBrand tag={Link} to="/" className={styles.logo}>
                    <figure>
                        <img src="/logo.png" alt="" />
                    </figure>
                </NavbarBrand>
                <div className={styles.locationsidebar}>
                    <Navigation>
                        {backOption && (
                            <div
                                key="navigationBack"
                                className={cn(styles.backSidebaritem)}
                                onClick={() => handleGoBack()}>
                                <BackIcon color={'#5bb784'} />
                                <div className={styles.backButtonTextWrapper}>
                                    {backOption.title}
                                </div>
                            </div>
                        )}
                        {currentSideBarOptions
                            ?.filter((item) => !item.page.parentId)
                            .map((page) => {
                                return (
                                    <GenericSidebarItem
                                        key={`${page.page.id}_${page.page.label}`}
                                        sidebarObject={page}
                                        subOptions={currentSideBarOptions.filter(
                                            (item) =>
                                                page.page.id === item.page.parentId &&
                                                page.page.id !== item.page.id
                                        )}
                                        allSidebarObjects={currentSideBarOptions}
                                        handleSetOpenSidebarOptions={handleSetOpenSideOptions}
                                        openSidebarOptions={openSidebarOptions}>
                                        {t(page.page.label)}
                                    </GenericSidebarItem>
                                );
                            })}
                    </Navigation>
                </div>
            </nav>
        </LeftColumn>
    );
};

export default GenericSidebar;
export { GenericSidebar };
